<template>
  <div class="telemetry-history-container">
    <div class="chart-header">
      <p class="label">{{ t('dashboard.telemetry.chart.title') }}</p>
      <div class="interactables">
        <button :disabled="loading" @click="refresh">
          <i v-if="loading" class="pi pi-spin pi-spinner"></i>
          <i v-else class="pi pi-refresh"></i>
        </button>
        <button @click="close">
          <i class="pi pi-times"></i>
        </button>
      </div>
    </div>
    <div class="chart-footer" role="radiogroup">
      <div class="w-full">
        <MultiSelect
          v-model="selectedTelemetries"
          :options="availTelemetryChannels"
          optionLabel="name"
          optionValue="path"
          :placeholder="t('hardwareSystem.selectTelemetryChannel')"
          class="w-full mb-4"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value.length > 0">
              <span v-if="selectedTelemetries.length <= 5">
                <span
                  v-for="(tItem, index) in slotProps.value"
                  :key="index"
                  class="inline-block mr-2"
                >
                  {{ availTelemetryChannels?.find((item: any) => item.path == tItem)?.name
                  }}{{ index + 1 !== selectedTelemetries.length ? ',' : '' }}
                </span>
              </span>
              <span v-else-if="selectedTelemetries.length > 5">
                {{ selectedTelemetries.length }} channels selected
              </span>
            </div>
            <span v-else class="custom-value">{{ $t('organisation.availableFeatures') }}</span>
          </template>
          <template #option="slotProps">
            <span class="pr-4">{{ slotProps.option.name }}</span>
            <i
              v-if="getTelemetryDescription(slotProps.option.name)"
              class="pi pi-info-circle text-neutral-500 cursor-default"
              v-tooltip.right="{
                value: `
                  <span class='p-2'>${getTelemetryDescription(slotProps.option.name)}</span>`,
                escape: true,
                class: 'custom-tooltip',
              }"
            ></i>
          </template>
        </MultiSelect>
        <div v-if="selectedTelemetries.length > 0">
          <div v-for="(tItem, index) in selectedTelemetries" :key="index" class="inline-block mr-2">
            <div
              data-cy="organisation-feature"
              class="custom-chip cursor-pointer"
              :style="`background-color: ${getColorForIndex(selectedTelemetries.findIndex((item: any)=>tItem == item))};`"
              @click="unselectTelemetryOption(tItem)"
            >
              <span>
                {{ availTelemetryChannels?.find((item: any) => item.path == tItem)?.name }}
              </span>
              <i class="pi pi-times text-xs font-bold"></i>
            </div>
          </div>
        </div>
      </div>
      <DateTimeInput
        v-if="!props.startTime"
        data-cy="event-history-time-from"
        ref="startDateTimeCalendar"
        class="w-1/2 h-8 mr-1"
        v-model="startTime"
        :maxDateTime="maxTimeFrom"
        :stepMinute="10"
        :placeholder="t('chargePark.timeFrom')"
      />
      <DateTimeInput
        v-if="!props.endTime"
        data-cy="event-history-time-to"
        ref="endDateTimeCalendar"
        class="w-1/2 h-8 ml-1"
        v-model="endTime"
        :minDateTime="minTimeTo"
        :maxDateTime="maxTimeTo"
        :stepMinute="10"
        :placeholder="t('chargePark.timeTo')"
      />
    </div>
    <div v-if="loading" class="message-container loading-container">
      <i class="pi pi-spin pi-spinner" />
      <p>{{ t('loading') }}</p>
    </div>
    <div v-else-if="error" class="message-container error-container">
      <p>{{ error }}</p>
    </div>
    <div
      v-else-if="emptyData"
      class="message-container nodata-container"
      :data-cy="`multi-telemetry-no-data`"
    >
      <exclamation-triangle-icon :style="{ height: '1rem', width: '1rem' }" />
      <p>{{ t('dashboard.telemetry.chart.nodata') }}</p>
    </div>
    <template v-else>
      <telemetry-fused-chart
        :data="data"
        :start-unix-epoch="rangeStart"
        :end-unix-epoch="rangeEnd"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { AvailableTelemetryOption, HardwareSystem } from '@/models/hardwareSystems.model';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
// PrimeVue
import MultiSelect from 'primevue/multiselect';
// Echarts
import { use } from 'echarts/core';
import { DataZoomComponent } from 'echarts/components';
use([DataZoomComponent]);
// i18n
import { useI18n } from 'vue-i18n';
// Types and API functions for fetching telemetry data
import type { FusedDataResponse } from '@/stores/admin/controlPanel/controlPanel.types';
import { fetchHistoricalTelemetryFusedData } from '@/stores/admin/controlPanel/controlPanel.api';
import { fetchAvailableTelemetryChannels } from '@/stores/admin/hardwareSystems/hardwareSystems.api';
// Pinia stores
import { useProductTypesStore } from '@/stores/admin/productTypes/productTypes.store';
// Custom components
import TelemetryFusedChart from '@/components/hardwareSystems/controlPanel/widgets/telemetry/TelemetryFusedChart.vue';
import DateTimeInput from '@/components/common/time/DateTimeInput.vue';
// Utilities
import { colorsPallette } from '@/utils/colors';
import type { ProductType } from '@/models/productTypes.model';

const props = defineProps<{
  systemId: HardwareSystem['id'];
  chargePointId?: number; // TODO move this with availableTelemetry to parent
  startTime: string;
  endTime: string;
}>();

const productTypesStore = useProductTypesStore();

const { t } = useI18n();

const emit = defineEmits(['close']);

const data = ref<FusedDataResponse | undefined>(undefined); // Telemetry data

const targetNames = ['Current', 'Voltage', 'Power', 'Energy session']; // Array of target telemetry names to filter by

const availTelemetryChannels = ref<AvailableTelemetryOption[]>(); // Ref to store available telemetry channels
const selectedTelemetries = ref<string[]>([]); // Ref to store selected telemetry channels paths
const productType = ref<ProductType>();

// Loading, empty data, and error flags
const loading = ref(true);
const emptyData = ref(false);
const error = ref<string | undefined>(undefined);

// Reactive variables for start and end times, defaulting to the last 7 days (used if props are not provided)
const startTime = ref<string>(new Date(Date.now() - 60 * 60 * 24 * 7 * 1000).toISOString());
const endTime = ref<string>(new Date().toISOString());

// Computed properties for start and end time in unix epoch seconds (for the chart)
const rangeStart = computed(() =>
  startTime.value ? new Date(startTime.value).valueOf() / 1000 : Date.now() / 1000 - 1000 * 60 * 60
);
const rangeEnd = computed(() =>
  endTime.value ? new Date(endTime.value).valueOf() / 1000 : Date.now() / 1000
);

// Computed properties for max and min date/time values for the date/time pickers
const maxTimeFrom = computed(() => {
  if (!endTime.value) return new Date().toISOString();
  return endTime.value;
});
const maxTimeTo = computed(() => {
  return new Date().toISOString();
});
const minTimeTo = computed(() => {
  if (!startTime.value) return undefined;
  return startTime.value;
});

// Function to unselect a telemetry option
const unselectTelemetryOption = (telemetryID: string) => {
  selectedTelemetries.value.splice(
    selectedTelemetries.value.findIndex((item) => item === telemetryID),
    1
  );
};

// Function to get a color from the palette based on index
const getColorForIndex = (index: number) => {
  return colorsPallette[index % colorsPallette.length];
};

// Helper function to check if the fetched telemetry data actually contains data points
function hasData(res: FusedDataResponse) {
  const xHasData = res && res.x.data.length > 0;
  const yHasData = res.y.length > 0;
  let anyYItemHasData = false;
  for (var i = 0; i < res.y.length; i++) {
    if (res.y[i].data.length > 0) anyYItemHasData = true;
  }
  return xHasData && yHasData && anyYItemHasData;
}

// Function to fetch/update the available telemetry channels for the selected system
const updateAvailableTelemetry = async () => {
  availTelemetryChannels.value = await fetchAvailableTelemetryChannels(props.systemId, {
    startTime: startTime.value,
    endTime: endTime.value,
  });
};

// Function to update the selected telemetry channels based on the available ones
const updateSelectedTelemetries = async () => {
  if (!availTelemetryChannels.value) return;

  const hasMatchingNames = availTelemetryChannels.value.some(
    (i) =>
      i.path.includes('chargePoint' + props.chargePointId) &&
      targetNames.some((name) => i.name.includes(name))
  );

  selectedTelemetries.value = availTelemetryChannels.value
    .filter((item) => item.path.includes('chargePoint' + props.chargePointId))
    .filter((item) => !hasMatchingNames || targetNames.some((name) => item.name.includes(name)))
    .map((item) => item.path);
};

// Match the productType property description to the telemetry name
const getTelemetryDescription = (telemetry: string) => {
  productTypesStore.productTypes.forEach((item) => {
    if (item.name === 'DirectPower') {
      productType.value = item;
    }
  });

  if (productType.value) {
    const arr = Object.keys(productType.value.properties.channels)
      .map((key) => {
        const name = productType.value?.properties.channels[key].name;
        const description = productType.value?.properties.channels[key].description;
        if (name && telemetry.split(': ')[1] === name) {
          return { name: name, description: description };
        }
      })
      .filter((item) => {
        if (item) {
          return telemetry.split(': ')[1] === item.name;
        }
      })
      .map((item) => {
        if (item && telemetry.split(': ')[1] === item.name) {
          return item.description;
        }
      });
    return arr[0];
  }
};

// Function to fetch telemetry data
const fetchData = async () => {
  let paths = selectedTelemetries.value.map((path) => {
    return { systemId: props.systemId, path: path };
  });

  loading.value = true;
  error.value = undefined;
  emptyData.value = false;

  await fetchHistoricalTelemetryFusedData({
    paths: paths,
    start: rangeStart.value,
    end: rangeEnd.value,
    aggregation: { type: 'maxPoints', method: 'subSample', value: 200 },
  })
    .then((response) => {
      if (!hasData(response)) emptyData.value = true;
      data.value = response;
    })
    .finally(() => {
      loading.value = false;
    });
};

const refresh = async () => await fetchData(); // Refresh data function
const close = () => emit('close'); // Close widget function

// Function to refresh both available and selected telemetries, and fetch data.
const refreshTelemetryAndData = async () => {
  await updateAvailableTelemetry();
  await updateSelectedTelemetries();
  await fetchData();
};

onMounted(async () => {
  // Override default start/end times with props if provided
  if (props.startTime) startTime.value = props.startTime;
  if (props.endTime) endTime.value = props.endTime;

  await refreshTelemetryAndData();
});

// Watchers to re-fetch data when dependencies change
watch(
  [() => props.systemId, () => props.chargePointId, () => props.startTime, () => props.endTime],
  async () => {
    if (props.startTime) startTime.value = props.startTime;
    if (props.endTime) endTime.value = props.endTime;
    await refreshTelemetryAndData(); // Call refresh function to update everything
  }
);

// Watch selectedTelemetries for changes and fetch data accordingly (no changes here)
watch(
  selectedTelemetries,
  async () => {
    if (props.startTime) startTime.value = props.startTime;
    if (props.endTime) endTime.value = props.endTime;
    await fetchData(); // Update chart data when selected telemetries change
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
div.telemetry-history-container {
  box-sizing: border-box;
  position: relative;
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
  overflow: hidden;
}

div.chart-header {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--gray-200);
  padding: 10px 16px;
  background-color: var(--gray-100);
}

div.chart-footer {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--gray-200);
  padding: 10px 16px;
  background-color: var(--gray-0);
}

div.interactables {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  margin-left: auto;
}

.chart {
  width: 100%;
  height: 400px;
  z-index: 0;
  background: var(--gray-0);
}

div.message-container {
  display: flex;
  padding: 1rem;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  min-height: 250px;
}

div.error-container {
  background-color: var(--gray-50);
  color: var(--red-primary);
}

div.nodata-container {
  background-color: var(--gray-0);
  color: var(--gray-500);
}

div.timerange-selector {
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
  height: 2rem;
  overflow: hidden;
}

p.label {
  font-weight: 700;
  font-size: 16px;
  color: var(--gray-700);
}

input[type='radio'] {
  display: none;
}

div.enum-list {
  display: grid;
  border-collapse: collapse;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  overflow-x: auto;
  overflow-y: clip;

  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);

  div.enum-item {
    display: flex;
    gap: 1rem;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: -1px;

    border-right: 1px solid var(--gray-200);
    border-bottom: 1px solid var(--gray-200);

    p {
      margin: 0;
      padding: 0;
    }

    &:nth-child(3n) {
      border-right: none;
      margin: 0;
    }
  }
}

label {
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem;
  height: 100%;
  background-color: var(--gray-0);

  &.disabled {
    cursor: not-allowed;
  }

  &:not(.selected).disabled {
    color: var(--gray-300);
  }

  &:not(.selected):not(.disabled):hover {
    cursor: pointer;
    background-color: var(--gray-100);
  }

  &.selected {
    background-color: var(--green-primary);
    color: var(--gray-0);
  }
}

label:not(:last-child) {
  border-right: 1px solid var(--gray-200);
}

button {
  border-radius: var(--rounded-md);
  height: 2rem;
  width: 2rem;
  border: 1px solid var(--gray-200);
  background-color: var(--gray-0);

  &:hover {
    cursor: pointer;
    background-color: var(--gray-100);
  }
}
</style>
