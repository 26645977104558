import axios from 'axios';
import type { AxiosPromise, AxiosResponse } from 'axios';
import type { UpdateNew, UpdateEditData } from '@/models/updates.model';

export function fetchUpdates(): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/update-packages/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function uploadUpdateFile(file: File): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(
      `/api/update-packages/file/`,
      { file },
      {
        headers: {
          uid: 1,
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function uploadUpdate(data: UpdateNew): Promise<AxiosResponse> {
  return axios
    .post<AxiosPromise>(
      `/api/update-packages/`,
      { ...data },
      {
        headers: {
          uid: 1,
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function editUpdate(data: UpdateEditData): Promise<AxiosResponse> {
  return axios
    .put<AxiosPromise>(
      `/api/update-packages/${data.id}/`,
      {
        version: data.version,
        description: data.description,
        product_type: data.product_type_name,
      },
      {
        headers: {
          uid: 1,
        },
      }
    )
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchSystemUpdates(productTypeName: string): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/update-packages/?product_type=${productTypeName}`, {
      headers: {
        uid: 1,
      },
    })
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function deleteUpdate(id: number): Promise<AxiosResponse> {
  return axios
    .delete<AxiosPromise>(`/api/update-packages/${id}/`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function fetchUpdateFilePath(url: string): Promise<AxiosResponse> {
  return axios
    .get<AxiosPromise>(`/api/update-packages/files/${url}`)
    .then((response: AxiosResponse) => response)
    .catch((error) => {
      return Promise.reject(error);
    });
}
