<template>
  <Dialog
    title="Branding Configuration"
    :visible="props.open"
    @update:visible="visibilityChanged"
    class="w-full m-4 max-w-[56rem]"
    modal
    :draggable="false"
  >
    <template v-slot:header>
      <div class="relative w-full text-center">
        <Button
          v-if="currentStep > 0"
          :label="$t('back')"
          icon="pi pi-angle-left"
          class="!absolute p-button-outlined !bg-white p-button-plain p-button-sm mr-2 left-0 top-1/2 transform -translate-y-1/2"
          @click="currentStep--"
        />

        <p class="font-semibold">Branding Config</p>
      </div>
    </template>

    <ModalStepper :active-step="currentStepId" v-bind="{ steps }" class="stepper" />

    <div>
      <branding-information-step
        v-show="currentStepId === 'information'"
        v-model:name="name"
        v-model:product-type-name="productTypeName"
        :product-types="productTypes"
        :template-selection-disabled="props.mode === 'EDIT'"
        :validation-errors="informationErrors"
      />

      <template v-if="currentStepId === 'configuration'">
        <div v-if="selectedTemplate === undefined">
          <error-bar title="No template found" message="No template found" />
        </div>
        <branding-configuration-step
          v-else
          :template="selectedTemplate"
          :values="configuration"
          @update:values="updateConfiguration($event)"
          :validation-errors="configurationErrors"
          :product-type-name="productTypeName"
        />
      </template>

      <template v-if="currentStepId === 'sites'">
        <branding-site-selection-step
          :current-branding-id="props.config?.id"
          :selected-site-ids="siteIds"
          :sites="sites"
          :product-type-name="productTypeName"
          @update:selected-site-ids="updateChargeParkIds($event)"
          :validation-errors="sitesErrors"
        />
      </template>

      <Toast data-cy="branding-create-edit-toast" position="top-center" :group="TOAST_GROUP" />
    </div>

    <template v-slot:footer>
      <Button
        v-if="currentStep < steps.length - 1"
        :label="$t('next')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        @click="nextStep"
        :disabled="!currentStepValid"
      />
      <Button
        v-else
        :label="$t('apply')"
        class="p-button-sm p-button-footer p-button-primary mx-auto mb-2 block mt-5"
        @click="applyChanges"
      />
    </template>
  </Dialog>
  <Toast />
</template>

<script setup lang="ts">
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import ModalStepper from '@/components/common/steppers/ModalStepper.vue';
import { computed, onMounted, ref, watch } from 'vue';
import type {
  BrandingSettings,
  BrandingTemplate,
  FullBrandingConfiguration,
  WriteableBrandingConfiguration,
} from '@/stores/admin/branding/branding.types';
import { createBranding, updateBranding } from '@/stores/admin/branding/ui.api';

import BrandingInformationStep from '@/components/branding/modal/BrandingInformationStep.vue';
import BrandingConfigurationStep from '@/components/branding/modal/BrandingConfigurationStep.vue';
import BrandingSiteSelectionStep from '@/components/branding/modal/BrandingSiteSelectionStep.vue';
import { fetchChargeParks } from '@/stores/admin/chargeParks/chargeParks.api';
import type { ChargePark } from '@/models/chargeParks.model';
import { fetchProductTypes } from '@/stores/admin/productTypes/productTypes.api';
import type { ProductType } from '@/models/productTypes.model';
import {
  type InformationValidationErrors,
  type ConfigurationValidationErrors,
  type SiteValidationErrors,
  validateInformation,
  validateBranding,
} from '@/stores/admin/branding/ui.validation';
import { fillMissingValuesInBrandingConfiguration } from '@/stores/admin/branding/ui.logic';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';
import { useI18n } from 'vue-i18n';

const toast = useToast();
const i18n = useI18n();
const TOAST_GROUP = 'branding-create-edit-toast';

const props = defineProps<{
  open: boolean;
  mode: 'CREATE' | 'EDIT';
  config?: FullBrandingConfiguration;
}>();

const currentStep = ref(0);
const currentStepId = computed(() => steps[currentStep.value]);
const steps = ['information', 'configuration', 'sites'] as const;

const name = ref<string>('');
const productTypeName = ref<string | undefined>(undefined);
const configuration = ref<BrandingSettings>({});
const siteIds = ref<number[]>([]);

const sites = ref<ChargePark[]>([]);
const productTypes = ref<ProductType[]>([]);

const informationErrors = ref<InformationValidationErrors | undefined>(undefined);
const configurationErrors = ref<ConfigurationValidationErrors | undefined>(undefined);
const sitesErrors = ref<SiteValidationErrors | undefined>(undefined);

const selectedTemplate = computed<BrandingTemplate | undefined>(() => {
  return productTypes.value.find((p) => p.name === productTypeName.value)?.properties
    .branding_template;
});

const emit = defineEmits<{
  (event: 'cancelled'): void;
  (event: 'created', id: number): void;
  (event: 'updated'): void;
}>();

function copy<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj));
}

function resetFormValues() {
  name.value = props.config?.name ?? '';
  productTypeName.value = props.config?.product_type_name ?? undefined;
  configuration.value = props.config?.settings ? copy(props.config.settings) : {};
  siteIds.value = props.config?.charge_parks?.map((site) => site.id) ?? [];
  currentStep.value = 0;
}

function updateChargeParkIds(chargeParkIds: number[]) {
  siteIds.value = chargeParkIds;
}

function showErrorToast(message: string) {
  toast.add({
    severity: 'error',
    summary: i18n.t('error'),
    detail: message,
    group: TOAST_GROUP,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
  });
}

function updateConfiguration(newConfiguration: BrandingSettings) {
  configuration.value = newConfiguration;
  recomputeValidationErrors();
}

function recomputeValidationErrors() {
  informationErrors.value = validateInformation(name.value, productTypeName.value);
  configurationErrors.value =
    selectedTemplate.value && configuration.value
      ? validateBranding(selectedTemplate.value, configuration.value)
      : undefined;
}

watch([name, productTypeName, configuration, siteIds], () => {
  recomputeValidationErrors();
});

watch(
  () => props.config,
  () => (configuration.value = props.config?.settings ? copy(props.config.settings) : {})
);

const informationStepValid = computed<boolean>(
  () =>
    informationErrors.value !== undefined &&
    Object.values(informationErrors.value).every((e) => e === null)
);

const configurationStepValid = computed<boolean>(
  () =>
    configurationErrors.value !== undefined &&
    Object.values(configurationErrors.value)
      .flatMap((group) => Object.values(group))
      .every((e) => e === null)
);

const sitesStepValid = computed<boolean>(
  () => sitesErrors.value !== undefined && Object.values(sitesErrors.value).every((e) => e === null)
);

const currentStepValid = computed<boolean>(() => {
  switch (currentStepId.value) {
    case 'information':
      return informationStepValid.value;
    case 'configuration':
      return configurationStepValid.value;
    case 'sites':
      return sitesStepValid.value;
    default:
      return false;
  }
});

async function applyChanges() {
  if (!name.value || !productTypeName.value) return;

  const config: WriteableBrandingConfiguration = {
    name: name.value,
    product_type_name: productTypeName.value,
    settings: configuration.value,
    charge_park_ids: siteIds.value,
  };

  if (props.mode === 'CREATE') {
    await createBranding(config)
      .then(({ id }) => emit('created', id))
      .catch((e) => showErrorToast(e));
  }

  if (props.mode === 'EDIT' && props.config?.id) {
    await updateBranding(props.config.id, config)
      .then(() => {
        emit('updated');
        resetFormValues();
      })
      .catch((e) => showErrorToast(e));
  }
}

function nextStep() {
  if (currentStepId.value === 'information' && selectedTemplate.value) {
    configuration.value = fillMissingValuesInBrandingConfiguration(
      selectedTemplate.value,
      configuration.value
    );
    recomputeValidationErrors();
  }
  currentStep.value += 1;
}

function visibilityChanged(isVisible: boolean) {
  recomputeValidationErrors();
  if (!isVisible) {
    currentStep.value = 0;
    resetFormValues();
    emit('cancelled');
  }
}

onMounted(async () => {
  resetFormValues();
  productTypes.value = (await fetchProductTypes())?.data ?? [];
  sites.value = (await fetchChargeParks())?.data ?? [];
  recomputeValidationErrors();
});
</script>

<style scoped lang="scss">
.stepper {
  border-bottom: 1px solid var(--gray-200);
}
</style>
