<template>
  <div class="flex rounded-md max-w-max flex-wrap gap-2">
    <HardwareSystemConnectorStatus
      v-for="(state, index) in fusedConnectorStates"
      :key="index"
      :model-value="state"
    >
    </HardwareSystemConnectorStatus>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
// Custom components
import HardwareSystemConnectorStatus from './HardwareSystemConnectorStatus.vue';
// Stores
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
// Types
import type {
  HardwareSystem,
  HardwareConnectorState,
  HardwareConnectorStates,
  FusedConnectorStates,
  ConnectorStatesIds,
} from '@/models/hardwareSystems.model';

const props = defineProps<{
  system: HardwareSystem;
  connectorState: HardwareConnectorState[];
}>();

// Initialize the hardware systems store
const hardwareSystemStore = useHardwareSystemsStore();

/**
 * Checks if an object is empty and properly typed
 * @param objectName - The object to check
 * @returns True if the object is empty and of type Object
 */
const isObjectEmpty = (objectName: Record<string, HardwareConnectorStates>): boolean => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

/**
 * Computed property that processes and fuses connector states
 */
const fusedConnectorStates = computed<FusedConnectorStates[]>(() => {
  // Transform connector state types into fused state objects
  return hardwareSystemStore.connectorStateTypes.map((stateType) => {
    let count = 0;
    const connector_ids: ConnectorStatesIds[] = [];

    // Process each system connector
    props.connectorState.forEach((systemConnector) => {
      // Skip processing if states are missing or empty
      if (!systemConnector.states || isObjectEmpty(systemConnector.states)) return;

      // Find the corresponding system's nidec_id
      const systemNidecID = hardwareSystemStore.systems.find(
        (system) => system.id === systemConnector.id
      )?.nidec_id;

      // Iterate through all connectors in the system
      for (let connectorId = 0; connectorId < systemConnector.number_of_connectors; connectorId++) {
        const state = systemConnector.states[connectorId]?.state;

        // Count and collect matching states
        if (state === stateType.id) {
          count++;
          connector_ids.push({
            system_id: systemNidecID,
            connector_id: connectorId,
          });
        }
      }
    });

    // Return fused state object for this state type
    return {
      state: stateType.id,
      count,
      name: stateType.name,
      color: stateType.color,
      connector_ids,
    };
  });
});
</script>
