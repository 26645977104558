<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    class="m-5"
    data-cy="updates-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}</Message
  >
  <DataTable
    v-if="!loading && !notAuthorized"
    data-cy="event-history-table"
    v-model:filters="filters"
    filterDisplay="menu"
    lazy
    :value="events"
    :loading="isFetchingData"
    @update:filters="onFilterStateChange"
    @sort="onSort($event)"
  >
    <template #empty>
      <span data-cy="event-history-empty" class="w-full block text-center">{{
        $t('history.empty')
      }}</span>
    </template>
    <template #header>
      <EventHistoryTableFilters
        ref="eventHistoryFilters"
        :types="headerSelectedEventGroupTypes"
        :default-start-date-time="startTime as Date"
        :default-end-date-time="endTime as Date"
        @update:types="setFilterByEventGroups"
        @update:start-date-time="onStartDateTimeChange"
        @update:end-date-time="onEndDateTimeChange"
        @update:query="onQueryUpdate"
        v-model:aggregateErrorTypes="aggregateErrorTypes"
      />
    </template>
    <Column
      class="no-top-border-row"
      field="created_at"
      :header="$t('time')"
      :sortable="true"
      dataType="created_at"
    >
      <template #body="{ data }: { data: HistoryAggregation }">
        <span data-cy="event-history-time" class="time whitespace-nowrap">
          <div v-if="(data as HistoryAggregation).first_occurrence">
            <date-time-display :date="(data as HistoryAggregation).first_occurrence" /><br/>
            <date-time-display :date="(data as HistoryAggregation).last_occurrence" />
          </div>

          <date-time-display v-else :date="(data as HistoryAggregation).created_at" />
        </span>
      </template>
    </Column>
    <Column class="no-top-border-row" field="type" :header="$t('type')" style="min-width: 5rem">
      <template #body="{ data }: { data: HistoryAggregation }">
        <span data-cy="event-history-type">{{ data.type }}</span>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      :header="$t('history.title')"
      style="max-width: 12rem"
      :filterMenuStyle="{ minWidth: '17rem' }"
      field="event"
      :showFilterMatchModes="false"
    >
      <template #body="{ data }: { data: HistoryAggregation }">
        <div class="flex flex-nowrap">
          <i
            :class="getIcon(data.event)"
            :style="{
              color: getIconColor(data.event),
            }"
          ></i>
          <span data-cy="event-history-event" class="pl-2 whitespace-nowrap">{{ data.event }}</span>
        </div>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          data-cy="event-history-event-filter"
          v-model="filterModel.value"
          filter
          optionLabel="name"
          optionGroupLabel="name"
          optionGroupChildren="events"
          placeholder="Select Event"
          class="p-column-filter"
          :options="groupedEventSubTypes"
          :showClear="true"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div class="flex align-items-center items-center">
                <i
                  :class="getIcon(slotProps.value.name)"
                  :style="{ color: getIconColor(slotProps.value.name) }"
                ></i>
                <span class="pl-2">{{ slotProps.value.name }}</span>
              </div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #optiongroup="slotProps">
            <div class="flex align-items-center">
              <span class="font-medium">{{ slotProps.option.name }}</span>
            </div>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center items-center ml-3">
              <div class="flex align-items-center items-center">
                <i
                  :class="getIcon(slotProps.option.name)"
                  :style="{ color: getIconColor(slotProps.option.name) }"
                ></i>
                <span class="pl-2">{{ slotProps.option.name }}</span>
              </div>
            </div>
          </template>
        </Dropdown>
      </template>
    </Column>
    <Column class="no-top-border-row w-[80%]" field="details" :header="$t('details')">
      <template #body="{ data }: { data: HistoryAggregation }">
        <div class="flex justify-between">
          <div class="flex flex-wrap">
            <div
              v-for="(item, index) in convertedDetails(data.details)"
              :key="index"
              class="mb-2 mr-6 last:mr-0 whitespace-nowrap"
            >
              <Badge data-cy="event-history-detail-tag" :value="item.tag"></Badge>
              <span
                v-if="item.tag.toLowerCase().includes('date')"
                data-cy="event-history-detail-content"
                class="pl-2"
                >{{ dayjs(item.content).format('L HH:mm') }}</span
              >
              <span
                v-else-if="item.content === null"
                data-cy="event-history-detail-content-empty"
                class="empty pl-2"
                >--</span
              >
              <span v-else data-cy="event-history-detail-content" class="pl-2 whitespace-normal">{{
                item.content
              }}</span>
            </div>
            <Tag
              v-if="showMore(data.details)"
              icon="pi pi-plus"
              class="p-tag-more mb-3"
              severity="info"
              :value="$t('more')"
            ></Tag>
          </div>
        </div>
      </template>
    </Column>

    <Column
      class="no-top-border-row no-break"
      field="aggregateErrors"
      :header="$t('history.count')"
      headerStyle="width: 5rem"
      sortable
    >
      <template #body="{ data }: { data: HistoryAggregation }">
        <div class="flex">
          {{ data.aggregated_information.Count }}
        </div>
      </template>
    </Column>

    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }: { data: HistoryAggregation }">
        <div class="flex">
          <Button
            data-cy="open-history-details-modal"
            :label="$t('view')"
            icon="pi pi-eye"
            class="p-button-outlined p-button-plain p-button-sm mr-2"
            @click="openEventDetailsModal(data)"
          />
        </div>
      </template>
    </Column>

    <template #footer v-if="pageCount > DEFAULT_EVENT_HISTORY_PAGE_SIZE">
      <Paginator
        :rows="DEFAULT_EVENT_HISTORY_PAGE_SIZE"
        :totalRecords="pageCount"
        :pageLinkSize="amountOfPages"
        :alwaysShow="false"
        @page="onPageChange"
      ></Paginator>
    </template>
  </DataTable>
  <EventHistoryDetailsVue :system="props.system" />
</template>

<script setup lang="ts">
import DataTable, {
  type DataTableFilterMeta,
  type DataTableFilterMetaData,
} from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Message from 'primevue/message';
import Badge from 'primevue/badge';
import EventHistoryDetailsVue from './EventHistoryDetails.vue';
import Tag from 'primevue/tag';
import Paginator from 'primevue/paginator';
import Dropdown from 'primevue/dropdown';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import EventHistoryTableFilters from './EventHistoryTableFilters.vue';
import dayjs from 'dayjs';

import { onMounted, ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { getIcon, getIconColor } from '@/utils/eventHistoryIcons';
import { isObject } from 'lodash';
import { DEFAULT_EVENT_HISTORY_PAGE_SIZE } from '@/utils/constants';
import { FilterService } from 'primevue/api';

import type { HardwareSystem, EventType } from '@/models/hardwareSystems.model';
import type { HistoryAggregation } from '@/models/history.model';
import type { PageState } from 'primevue/paginator';
import type { DataTableSortEvent } from 'primevue/datatable';

const props = defineProps<{
  system: HardwareSystem;
}>();

const hardwareSystemsStore = useHardwareSystemsStore();
const { events, eventTypes, eventsMeta, eventDetailsModalIsOpen, eventDetails, isFetchingData } =
  storeToRefs(hardwareSystemsStore);
const loading = ref(true);
const notAuthorized = ref(false);
const pageCount = ref<number>(0);

const eventHistoryFilters = ref<typeof EventHistoryTableFilters>();

// Filters
const eventFilterMode = ref<'BY_EVENT_GROUP' | 'BY_EVENT_SUBTYPE'>('BY_EVENT_GROUP');
const searchQuery = ref<string | null>(null);
const headerSelectedEventGroupTypes = defineModel<number[]>('filteredEventGroupTypes');
const tableSelectedEventGroupTypes = ref<number[]>([]);
const tableSelectedEventSubType = ref<number | null>(null);
const startTime = defineModel<Date | null>('startTime');
const endTime = defineModel<Date | null>('endTime');
const pageNumber = ref<number>(0);
const ordering = ref<string | null>(null);
const aggregateErrorTypes = ref<boolean>(true);

const emit = defineEmits(['switchEventTypeAggregation']);

const EVENTS = 'EVENTS';

type GroupedEventSubTypeItem = {
  id: number;
  name: string;
  events: {
    id: number;
    name: string;
    typeId: number;
  }[];
};

const groupedEventSubTypes = computed<GroupedEventSubTypeItem[]>(() => {
  return eventTypesOptions.value.map((type) => ({
    ...type,
    events: type.events.map((event) => ({
      ...event,
      typeId: type.id,
    })),
  }));
});

function onFilterStateChange(filterState: DataTableFilterMeta) {
  const state = filterState['event'] as DataTableFilterMetaData as {
    value: GroupedEventSubTypeItem['events'][number] | null;
    matchMode: string;
  };

  eventFilterMode.value = 'BY_EVENT_SUBTYPE';

  if (state && state.value) {
    headerSelectedEventGroupTypes.value = [];
    tableSelectedEventGroupTypes.value = [state.value.typeId];
    tableSelectedEventSubType.value = state.value.id;

    setTimeout(resetPageAndFetchData, 200);
  } else {
    tableSelectedEventGroupTypes.value = [];
    tableSelectedEventSubType.value = null;

    setTimeout(resetPageAndFetchData, 200);
  }
}

const eventTypesOptions = ref<EventType[]>(eventTypes.value);

function onStartDateTimeChange(newDate: Date | null) {
  startTime.value = newDate;

  setTimeout(resetPageAndFetchData, 200);
}

function onEndDateTimeChange(newDate: Date | null) {
  endTime.value = newDate;

  setTimeout(resetPageAndFetchData, 200);
}

function onQueryUpdate(newQuery: string) {
  searchQuery.value = newQuery;

  setTimeout(resetPageAndFetchData, 200);
}

function setFilterByEventGroups(eventGroups: number[]) {
  eventFilterMode.value = 'BY_EVENT_GROUP';
  headerSelectedEventGroupTypes.value = eventGroups;
  filters.value.event.value = null;
  setTimeout(resetPageAndFetchData, 200);
}

function resetPageAndFetchData() {
  pageNumber.value = 0;
  fetchFilteredEvents();
}

function onPageChange(event: PageState) {
  pageNumber.value = event.page;
  fetchFilteredEvents();
}

function onSort(event: DataTableSortEvent) {
  if (event.sortField === 'aggregateErrors') {
    ordering.value = event.sortOrder === 1 ? 'count' : '-count';
  } else if (event.sortField === 'created_at') {
    ordering.value = event.sortOrder === 1 ? 'created_at' : '-created_at';
  }

  fetchFilteredEvents();
}

async function fetchFilteredEvents() {
  const types =
    eventFilterMode.value === 'BY_EVENT_GROUP'
      ? headerSelectedEventGroupTypes.value
      : tableSelectedEventGroupTypes.value;
  const event = eventFilterMode.value === 'BY_EVENT_GROUP' ? null : tableSelectedEventSubType.value;

  await hardwareSystemsStore.fetchEvents(props.system.id, {
    pageNumber: pageNumber.value + 1,
    pageSize: DEFAULT_EVENT_HISTORY_PAGE_SIZE,
    startTime: startTime.value ? dayjs(startTime.value).utc().format() : undefined,
    endTime: endTime.value ? dayjs(endTime.value).utc().format() : undefined,
    type: types,
    event: event ?? undefined,
    details: searchQuery.value ?? undefined,
    ordering: ordering.value ?? undefined,
    aggregateErrors: true,
  });
}

watch(
  () => eventsMeta.value.count,
  (count) => (pageCount.value = count)
);

const amountOfPages = computed(() => {
  if (eventsMeta.value.count / DEFAULT_EVENT_HISTORY_PAGE_SIZE > 10) {
    return 10;
  } else {
    return Math.ceil(eventsMeta.value.count / DEFAULT_EVENT_HISTORY_PAGE_SIZE) % 1 !== 0
      ? Math.ceil(eventsMeta.value.count / DEFAULT_EVENT_HISTORY_PAGE_SIZE) + 1
      : Math.ceil(eventsMeta.value.count / DEFAULT_EVENT_HISTORY_PAGE_SIZE);
  }
});

const convertedDetails = (details: any) => {
  const index = details.map((item: any) => item.content).findIndex((item: any) => isObject(item));

  if (index !== -1) {
    return details.slice(0, index);
  } else if (details.length > 4) {
    return details.slice(0, 4);
  } else {
    return details;
  }
};

const showMore = (details: any) => {
  const contentIsObject = details
    .map((item: any) => item.content)
    .some((value: any) => isObject(value));

  return (details.length <= 4 && contentIsObject) || details.length > 4;
};

function openEventDetailsModal(data: HistoryAggregation) {
  eventDetails.value = data;
  eventDetailsModalIsOpen.value = true;
}

const filters = ref({
  event: { value: null, matchMode: EVENTS },
});

FilterService.register(EVENTS, (value, filter): boolean => {
  if (filter === undefined || filter === null) {
    return true;
  }

  return value === filter.name;
});

onMounted(async () => {
  setTimeout(() => {
    if (eventHistoryFilters.value) eventHistoryFilters.value.aggregateErrorTypes = true;
  }, 200);

  await Promise.all([fetchFilteredEvents(), hardwareSystemsStore.fetchEventTypes()])
    .catch((error) => {
      if (error.response?.status === 403) {
        notAuthorized.value = true;
      } else {
        throw new Error('Events failed to be fetched');
      }
    })
    .finally(() => {
      pageCount.value = eventsMeta.value.count;
      eventTypesOptions.value = eventTypes.value.map((item) => ({
        //HACK
        ...item,
        events: item.events.filter(
          (event) => !event.name.includes('(on)') && !event.name.includes('(off)')
        ),
      }));
      loading.value = false;
    });
});

watch(aggregateErrorTypes, (to) => emit('switchEventTypeAggregation', to));
</script>

<style lang="scss" scoped>
:deep(.p-badge) {
  background-color: var(--gray-200);
  color: var(--gray-500);
  border-radius: 3px;
}

.p-datatable {
  margin: 5px;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
}

:deep(.p-datatable-header) {
  padding: 30px 20px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
</style>
