<template>
  <image-element :id="props.id" :product-type-name="props.productTypeName" class="image" />
</template>

<script setup lang="ts">
import ImageElement from '@/components/branding/common/ImageElement.vue';
import type { ProductType } from '@/models/productTypes.model';

const props = defineProps<{
  id?: any;
  productTypeName: ProductType['name'];
}>();
</script>

<style scoped lang="scss">
.image {
  background-color: var(--gray-50);
  border-radius: var(--rounded-md);
  border: 1px solid var(--gray-200);
  height: 4rem;
  width: 8rem;
  object-fit: contain;

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 0.9rem;
    color: var(--gray-400);
  }
}
</style>
