<template>
  <Dialog
    data-cy="updates-upload-modal"
    :header="modalTitle"
    v-model:visible="isVisible"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '550px' }"
    :modal="true"
    @hide="closeModal"
  >
    <FileUpload
      v-if="!uploadIsEditing"
      name="update"
      @select="onSelectedFile"
      :showCancelButton="false"
      :showUploadButton="false"
      chooseIcon="pi pi-file"
      :chooseLabel="t('selectFile')"
      :class="`${updateData.file_id && fileIsUploaded ? 'disabled' : ''}`"
    >
      <template #content>
        <div v-if="updateFile && !isFailed" class="flex flex-wrap">
          <div class="flex items-center w-full">
            <i class="pi pi-file icon mr-2"></i>
            <span class="font-semibold">{{ updateFile.name }}</span>
            <i v-if="fileIsLoading" class="pi pi-spin pi-spinner icon icon-loading ml-4"></i>
            <i
              v-if="fileIsUploaded && uploadSuccess"
              class="pi pi-check-circle icon icon-success ml-4"
            ></i>
          </div>
        </div>
        <div v-if="isFailed">
          <div class="flex justify-center items-center flex-wrap drag-and-drop-wrapper">
            <i class="pi pi-cloud-upload" />
            <p class="mt-4 info w-full text-center">{{ $t('dragAndDrop') }}</p>
          </div>
        </div>
        <div v-if="fileIsUploaded && uploadSuccess" class="field flex flex-col w-full mt-8">
          <label for="product-type" class="mt-4 mb-2 field-edit-label">{{
            $t('productType.title')
          }}</label>
          <Dropdown
            data-cy="updates-upload-product-type"
            id="product-type"
            v-model="updateData.product_type_name"
            option-value="name"
            :options="productTypes"
            :placeholder="$t('select', { name: $t('productType.title') })"
            class="p-column-filter mb-2"
            optionLabel="name"
          >
          </Dropdown>

          <label for="version" class="mt-4 mb-2 field-edit-label">{{ $t('version') }}</label>
          <InputText
            data-cy="updates-upload-version"
            id="version"
            type="text"
            aria-describedby="version-help"
            class="p-inputtext-sm"
            v-model="updateData.version"
            :placeholder="$t('version')"
            @keyup="validateVersion"
          />
          <div class="input-errors" v-for="(error, index) of v$.version.$errors" :key="index">
            <small class="p-error">{{ error.$message }}</small>
          </div>

          <label for="description" class="mt-4 mb-2 field-edit-label">{{
            $t('description')
          }}</label>
          <Textarea
            data-cy="updates-upload-description"
            id="description"
            class="p-inputtext-sm"
            aria-describedby="description-help"
            v-model="updateData.description"
            :placeholder="$t('description')"
            @keyup="validateDescription"
            autoResize
            rows="3"
            cols="30"
          />
          <div class="input-errors" v-for="(error, index) of v$.description.$errors" :key="index">
            <small class="p-error">{{ error.$message }}</small>
          </div>
        </div>
      </template>
      <template #empty>
        <div class="flex justify-center items-center flex-wrap drag-and-drop-wrapper">
          <i class="pi pi-cloud-upload" />
          <p class="mt-4 info w-full text-center">{{ $t('dragAndDrop') }}</p>
        </div>
      </template>
    </FileUpload>
    <div v-else class="field flex flex-col w-full p-8 mt-8">
      <div class="flex items-center w-full">
        <i class="pi pi-file icon mr-2"></i>
        <span class="font-semibold">{{ uploadedFileName }}</span>
      </div>
      <label for="product-type" class="mt-4 mb-2 field-edit-label">{{
        $t('productType.title')
      }}</label>
      <Dropdown
        data-cy="updates-upload-product-type"
        id="product-type"
        v-model="selectedProductType"
        :options="productTypes"
        :placeholder="$t('select', { name: $t('productType.title') })"
        class="p-column-filter mb-2"
        option-label="name"
        option-value="id"
        @change="setSelectedProductType"
      >
      </Dropdown>

      <label for="version" class="mt-4 mb-2 field-edit-label">{{ $t('version') }}</label>
      <InputText
        data-cy="updates-upload-version"
        id="version"
        type="text"
        aria-describedby="version-help"
        class="p-inputtext-sm"
        v-model="updateData.version"
        :placeholder="$t('version')"
        @keyup="validateVersion"
      />
      <div class="input-errors" v-for="(error, index) of v$.version.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>

      <label for="description" class="mt-4 mb-2 field-edit-label">{{ $t('description') }}</label>
      <Textarea
        data-cy="updates-upload-description"
        id="description"
        class="p-inputtext-sm"
        aria-describedby="description-help"
        v-model="updateData.description"
        :placeholder="$t('description')"
        @keyup="validateDescription"
        autoResize
        rows="3"
        cols="30"
      />
      <div class="input-errors" v-for="(error, index) of v$.description.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        @click="closeModal"
      />
      <Button
        v-if="!uploadIsEditing"
        data-cy="updates-add-button"
        :label="t('add')"
        class="p-button-sm p-button-primary"
        :disabled="formIsNotValid"
        @click="saveUpdate"
        :loading="loading"
      ></Button>
      <Button
        v-if="uploadIsEditing"
        data-cy="updates-edit-button"
        :label="t('edit')"
        class="p-button-sm p-button-primary"
        :disabled="editFormIsNotValid"
        @click="editUpdate"
        :loading="loading"
      ></Button>
    </template>
  </Dialog>
  <Toast
    data-cy="updates-upload-toast"
    position="top-center"
    errorIcon="pi pi-info-circle"
    group="updates-upload"
  >
    <template v-if="!fileTypeIsValid" #message="slotProps">
      <div class="flex flex-col w-full pt-1">
        <div class="w-full flex items-bottom">
          <span class="p-toast-message-icon pi pi-info-circle"></span>
          <div class="p-toast-message-text">
            <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
          </div>
        </div>
        <div>
          <span data-cy="updates-upload-toast-error" class="block my-2 ml-8">{{
            fileErrorDetails
          }}</span>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { useUpdatesStore } from '@/stores/admin/updates/updates.store';
import { useProductTypesStore } from '@/stores/admin/productTypes/productTypes.store';
import { useI18n } from 'vue-i18n';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import FileUpload from 'primevue/fileupload';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { asciiCharacters } from '@/utils/validationRules';
import Dropdown from 'primevue/dropdown';
import { FILE, UPDATE, MAX_LENGTH, DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';
import Textarea from 'primevue/textarea';

const { t } = useI18n();
const toast = useToast();
const store = useUpdatesStore();
const productTypesStore = useProductTypesStore();
const { productTypes } = storeToRefs(productTypesStore);
const { uploadUpdatesModalIsOpen, uploadIsEditing, uploadEditionData } = storeToRefs(store);
const isVisible = ref(false);
const loading = ref(false);
const isFailed = ref(false);
const fileErrorDetails = ref('');
const uploadSuccess = ref(false);
const fileIsUploaded = ref(false);
const fileIsLoading = ref(false);
const fileTypeIsValid = ref(true);
const updateFile = ref<File | null>(null);
const updateData = ref({
  id: 0,
  file_id: '',
  description: '',
  version: '',
  product_type_name: '',
});
const uploadedFileName = ref();
const selectedProductType = ref(0);

const modalTitle = computed(() =>
  uploadIsEditing.value ? t('update.editUpdate') : t('update.uploadNewUpdate')
);

const closeModal = () => {
  uploadUpdatesModalIsOpen.value = false;
  loading.value = false;

  resetForm();

  if (uploadIsEditing.value) {
    uploadIsEditing.value = false;
    store.resetUpdateEditionData();
  }
};

const resetForm = () => {
  uploadSuccess.value = false;
  fileIsUploaded.value = false;
  updateFile.value = null;
  uploadIsEditing.value = false;

  updateData.value = {
    id: 0,
    file_id: '',
    description: '',
    version: '',
    product_type_name: ''
    
  };

  v$.value.description.$reset();
  v$.value.version.$reset();
  v$.value.product_type_name.$reset();
  v$.value.$reset();
};

const formIsNotValid = computed(() => {
  return (
    v$.value.$error ||
    updateData.value.file_id === '' ||
    updateData.value.description === '' ||
    updateData.value.version === '' ||
    updateData.value.product_type_name === ''
  );
});

const editFormIsNotValid = computed(() => {
  return (
    !v$.value.$anyDirty ||
    (v$.value.description.$anyDirty && v$.value.description.$error) ||
    (v$.value.version.$anyDirty && v$.value.version.$error) ||
    (v$.value.product_type_id.$anyDirty && v$.value.product_type_id.$error)
  );
});

const showSuccess = (actionName: string) => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: actionName === FILE ? t('file') : t('update.title'),
      action: actionName === FILE ? t('toast.uploaded') : t('toast.added'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'updates-upload',
  });
};

const showError = (actionName: string) => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action:
        actionName === FILE
          ? t('toast.upload').toLocaleLowerCase()
          : t('toast.adding').toLocaleLowerCase(),
      name: actionName === FILE ? t('file') : t('update.title').toLocaleLowerCase(),
    }),
    group: 'updates-upload',
  });
};

const showEditSuccess = () => {
  toast.add({
    severity: 'success',
    summary: t('toast.success', {
      name: t('update.title'),
      action: t('toast.edited'),
    }),
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
    group: 'updates-upload',
  });
};

const showEditError = () => {
  toast.add({
    severity: 'error',
    summary: t('toast.error', {
      action: t('toast.edition'),
      name: t('update.title').toLocaleLowerCase(),
    }),
    group: 'updates-upload',
  });
};

const onSelectedFile = async (event: any) => {
  updateFile.value = event.files.length > 1 ? event.files.slice(-1)[0] : event.files[0];
  fileIsLoading.value = true;
  loading.value = true;

  await store
    .uploadFile(event.files.length > 1 ? event.files.slice(-1)[0] : event.files[0])
    .then((response: any) => {
      loading.value = false;
      if (response.status === 201) {
        toast.removeGroup('updates-upload');
        isFailed.value = false;
        fileTypeIsValid.value = true;
        fileIsUploaded.value = true;
        uploadSuccess.value = true;
        updateData.value.file_id = response.data.id;
        productTypesStore.fetchProductTypesList();
        showSuccess(FILE);
        fileIsLoading.value = false;
      }
    })
    .catch((error) => {
      isFailed.value = true;
      fileIsUploaded.value = true;
      uploadSuccess.value = false;
      fileIsLoading.value = false;
      loading.value = false;
      toast.removeGroup('updates-upload');

      if (error.response && error.response.status === 400 && error.response.data.file) {
        fileErrorDetails.value = error.response.data.file.error_details.error_message;
        fileTypeIsValid.value = false;
        showError(FILE);
      } else {
        showError(FILE);
      }
      updateData.value.file_id = '';
      uploadUpdatesModalIsOpen.value = true;

      throw new Error(error);
    });
};

const saveUpdate = async () => {
  const data = {
    description: updateData.value.description,
    file_id: updateData.value.file_id,
    version: updateData.value.version,
    product_type_name: updateData.value.product_type_name,
  };

  loading.value = true;

  await store
    .uploadUpdateData(data)
    .then((response: any) => {
      console.log("Success", response)
      loading.value = false;
      if (response.status === 201) {
        
        toast.removeGroup('updates-upload');
        showSuccess(UPDATE);
        closeModal();
      }
    })
    .catch((error) => {
      console.error("Fail", error)
      loading.value = false;
      toast.removeGroup('updates-upload');
      showError(UPDATE);
      throw new Error(error);
    });
};

const editUpdate = async () => {
  const data = {
    id: updateData.value.id,
    description: updateData.value.description,
    version: updateData.value.version,
    product_type_name: updateData.value.product_type_name,
  };

  loading.value = true;

  await store
    .editUpdateData(data)
    .then((response: any) => {
      loading.value = false;
      if (response.status === 200) {
        toast.removeGroup('updates-upload');
        showEditSuccess();
        closeModal();
      }
    })
    .catch((error) => {
      loading.value = false;
      toast.removeGroup('updates-upload');
      showEditError();
      throw new Error(error);
    });
};

const setSelectedProductType = async (data: any) => {
  selectedProductType.value = data.value.name;
  updateData.value.product_type_name = data.value.name;

  await v$.value.product_type_id.$validate();
};

const validateDescription = async () => {
  await v$.value.description.$validate();
};

const validateVersion = async () => {
  await v$.value.version.$validate();
};

watch(
  () => uploadUpdatesModalIsOpen.value,
  (value) => {
    if (value) {
      toast.removeGroup('updates-upload');
      fileErrorDetails.value = '';
      isFailed.value = false;
      isVisible.value = true;
    } else {
      isVisible.value = false;
    }
  }
);

watch(
  () => uploadIsEditing.value,
  (value) => {
    if (value) {
      updateData.value.id = uploadEditionData.value.id;
      uploadedFileName.value = uploadEditionData.value.file_name;
      updateData.value.version = uploadEditionData.value.version;
      updateData.value.description = uploadEditionData.value.description;
      updateData.value.product_type_name = uploadEditionData.value.product_type_name;
      selectedProductType.value = uploadEditionData.value.product_type_id;
    }
  },
  { immediate: true }
);

onMounted(() => {
  if (uploadIsEditing) {
    productTypesStore.fetchProductTypesList();
  }
});

const rules = computed(() => {
  return {
    description: {
      required: helpers.withMessage(
        t('rules.required', { field: t('description'), item: t('update.title').toLowerCase() }),
        required
      ),
      asciiCharacters: helpers.withMessage(
        t('rules.asciiCharacters', { field: t('description') }),
        asciiCharacters
      ),
    },
    version: {
      required: helpers.withMessage(
        t('rules.required', { field: t('version'), item: t('update.title').toLowerCase() }),
        required
      ),
      asciiCharacters: helpers.withMessage(
        t('rules.asciiCharacters', {
          field: t('version'),
        }),
        asciiCharacters
      ),
      maxLength: maxLength(MAX_LENGTH),
    },
    product_type_name: {
      required: helpers.withMessage(
        t('rules.required', { field: t('version'), item: t('update.title').toLowerCase() }),
        required
      ),
    },
  };
});

const v$ = useVuelidate(rules, updateData.value);
</script>

<style scoped lang="scss">
.pi-cloud-upload {
  color: $grey-medium;
  border: 3px solid $grey-medium;
  border-radius: 100px;
  font-size: 2.5rem;
  padding: 15px;
}

.info {
  color: $text-medium;
}
.drag-and-drop-wrapper {
  border: 2px solid $grey-medium-light;
  border-radius: 5px;
  padding: 30px;
}
.icon {
  font-size: 1.2rem;
  color: $text-medium;

  &-success {
    color: $primary;
    font-size: 1.5rem;
  }

  &-error {
    color: $error-toast;
    font-size: 1.5rem;
  }
  &-loading {
    color: $text-light;
    font-size: 1.5rem;
  }
}
</style>
