import type { GenericSetting } from '@/models/datatypes.model';
import type { IdName, IdValue } from '@/models/common.model';

export interface Invocation {
  id: number;
  name: string;
  type: number;
  severity: number;
  parameter: {
    value: number;
  };
  system_id: number;
  system_name: string | null;
  charge_park_name: string | null;
  charge_park_id: number | null;
  created_at: string;
  details: string | null;
}

export interface AlertContact {
  id: number;
  name: string;
  type: string;
}

export interface EnumValue {
  value: string;
  label: string;
  selectable?: boolean;
}

export interface Alert {
  id: number;
  name: string;
  active: boolean;
  parameters: AlertParameter[];
  contacts: AlertContact[];
  charge_parks: IdName[];
  invocations: IdName[];
  triggered_at: Date;
  send_on_weekends: boolean;
  max_rate_per_day: null | number;
  created_by_user_name: string;
  template_id: string;
  template_name?: string;
}

export interface AlertEditDataSend {
  name: string | null;
  parameters: IdValue[];
  contacts: number[];
  charge_parks: number[];
  send_on_weekends: boolean;
  max_rate_per_day: number;
  template_id: string;
}

export interface AlertEditData extends AlertEditDataSend {
  created_by_user_name: string;
}

export interface AlertBatchEditData {
  items: {
    id: number;
    active: boolean;
  }[];
}

export interface AlertParameter {
  id: string;
  value: number | string | boolean;
  name: string;
  unit: string;
  label: string | null;
  type: 'float' | 'enum' | 'bool';
}

export interface ProductTypeConfig {
  productTypeId: string;
  config: {
    channel: string;
  };
}

export interface AlertTemplate {
  id: string;
  name: string;
  description: string;
  type: string;
  severity: string;
  supportedProductTypes: string[];
  productTypeConfig: ProductTypeConfig[];
  parameters: GenericSetting[];
}

export interface AlertTemplateGroup {
  groupName: string;
  alertTemplates: AlertTemplate[];
}

export interface AlertTemplateWithGroupName extends AlertTemplate {
  groupName: string;
}

export enum AlertStep {
  Template = 'template',
  Configuration = 'configuration',
  Sites = 'sites',
  Contacts = 'contacts',
  Preview = 'preview',
}
