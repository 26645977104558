<template>
  <div class="flex justify-between items-center">
    <p data-cy="system-info-field-name" class="font-sm py-3 whitespace-nowrap flex items-center">
      {{ props.title }}
      <span
        v-if="props.tooltip"
        class="pi pi-info-circle text-md relative ml-1"
        v-tooltip.top="props.tooltip"
      ></span>
    </p>
    <div
      v-if="props.value !== undefined && props.value !== null"
      data-cy="system-info-field-value"
      :class="`py-3 pl-4 w-2/3 text-right font-medium text-[var(--gray-700)]}`"
    >
      <date-time-display v-if="props.isDateTime" :date="props.value as string" />
      <span v-else :class="`${props.breakAll ? 'break-all' : ''}`">
        {{ props.value }} {{ props.unitSuffix }}
      </span>
    </div>
    <span v-else class="empty py-2 px-4 font-medium">--</span>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import DateTimeDisplay from '../common/time/DateTimeDisplay.vue';

const { t } = useI18n();
const props = defineProps<{
  title: string;
  value: string | Number | null;
  isDateTime?: boolean;
  breakAll?: boolean;
  unitSuffix?: string;
  tooltip?: string;
}>();
</script>
