<template>
  <div class="relative">
    <div v-if="fetchingPreview" class="loading-overlay">
      <loading-spinner />
    </div>
    <div v-else-if="fetchPreviewError" class="loading-overlay bg-red-100">
      <exclamation-triangle-icon class="h-4 w-4 text-red-500" />
      <p class="text-sm text-red-500">
        {{ fetchPreviewError }}
      </p>
    </div>
    <img v-else :src="previewUrl" alt="No preview available" />
  </div>
</template>

<script setup lang="ts">
import { fetchBrandingMediaInfo } from '@/stores/admin/branding/ui.api';
import { onMounted, ref, watch } from 'vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import { extractApiError } from '@/utils/errors';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';
import type { ProductType } from '@/models/productTypes.model';

const props = defineProps<{
  id: number | string | null | undefined;
  productTypeName: ProductType['name'];
}>();

const fetchingPreview = ref(true);
const fetchPreviewError = ref<string | undefined>(undefined);
const previewUrl = ref<string | undefined>(undefined);

async function fetchPreview() {
  if (!props.id) {
    fetchingPreview.value = false;
    previewUrl.value = undefined;
    return;
  }

  fetchingPreview.value = true;
  fetchPreviewError.value = undefined;

  if (typeof props.id === 'string') {
    fetchingPreview.value = false;
    previewUrl.value = `${
      import.meta.env.VITE_BASE_API_URL
    }/api/brandings/static_file/?product_type_name=${props.productTypeName}&file_name=${props.id}`;
  } else {
    await fetchBrandingMediaInfo(props.id)
      .then(({ thumbnail_url }) => (previewUrl.value = thumbnail_url))
      .catch((err) => (fetchPreviewError.value = extractApiError(err)))
      .finally(() => (fetchingPreview.value = false));
  }
}

watch(
  () => props.id,
  () => fetchPreview()
);

onMounted(() => fetchPreview());
</script>

<style scoped lang="scss">
.loading-overlay {
  position: absolute;
  opacity: 0.5;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

img {
  height: 100%;
  width: 100%;
  border-radius: var(--rounded-md);
  object-fit: contain;

  text-align: center;
  color: var(--gray-400);
  font-size: 0.9rem;
  line-height: 1;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
