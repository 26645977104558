import type {
  WidgetData,
  WidgetFilter,
  WidgetQuery,
} from '@/stores/admin/dashboard/dashboard.types';
import { examplePanel } from '@/stores/admin/dashboard/dashboard.data';
import axios from 'axios';
import { mergeFilters } from '@/stores/admin/dashboard/dashboard.logic';
import { Err, type Result } from '@/@types';
import { Ok } from '@/@types';
import type { ChargePark } from '@/models/chargeParks.model';

export async function loadPanel() {
  return examplePanel;
}

export async function loadWidgetData(
  query: WidgetQuery,
  additionalFilters: WidgetFilter[] = []
): Promise<Result<WidgetData>> {
  const queryWithFilters = mergeFilters(query, additionalFilters);

  return await axios
    .post(`/api/data/aggregate/`, queryWithFilters)
    .then((res) => Ok(res.data))
    .catch((e) => Err(e));
}

export function fetchSystemStates(id?: ChargePark['id']): Promise<Result<WidgetData>> {
  let route = `/api/systems/status/`;
  if (id !== undefined) route += `?charge_park=${id}`;
  return axios
    .get(route)
    .then((res) => Ok(res.data))
    .catch((e) => Err(e));
}

export function fetchConnectorsStates(id?: ChargePark['id']): Promise<Result<WidgetData>> {
  let route = `/api/systems/connector-states/`;
  if (id !== undefined) route += `?charge_park=${id}`;
  return axios
    .get(route)
    .then((res) => Ok(res.data))
    .catch((e) => Err(e));
}

export async function fetchCount() {
  return axios.get(`/api/charging-sessions/statistics/?type=count`).then((res) => Ok(res.data));
}

export async function fetchAverageDuration() {
  return axios
    .get(`/api/charging-sessions/statistics/?type=average_duration`)
    .then((res) => Ok(res.data));
}
