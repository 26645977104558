<template>
  <div class="picker-table mt-2 pt-4 px-12">
    <span class="block title font-medium mb-3">{{ $t('update.firmware') }}</span>
    <div class="container">
      <div v-if="loading" class="loader-wrapper loader-wrapper-sm">
        <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
      </div>
      <DataTable
        v-if="!loading"
        :value="systemUpdates"
        dataKey="id"
        :scrollable="true"
        scrollHeight="240px"
        class="p-datatable-sm picker-table"
        v-model:filters="filters"
        filterDisplay="menu"
        :loading="loading"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="10"
        :globalFilterFields="['description', 'version']"
        v-model:selection="selectedSystemUpdate"
        @rowSelect="onSelectedSystemUpdate"
        selectionMode="single"
        :metaKeySelection="false"
      >
        <template #empty>{{ $t('update.empty') }}</template>
        <template #loading>{{ $t('update.loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('searchBy', { name: `${$t('description')}, ${$t('version')}` })"
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          selectionMode="single"
          style="flex: 0 0 0; padding: 0"
          headerStyle="padding-left: 36px"
          bodyStyle="padding-left: 16px"
        ></Column>
        <Column
          field="version"
          :header="$t('version')"
          :sortable="true"
          style="flex: 0 0 0"
          headerStyle="minWidth: 150px"
          bodyStyle="minWidth: 150px"
        >
          <template #body="{ data }">
            <div class="flex justify-start">
              <span data-cy="system-update-version" class="text-sm font-medium">{{
                data.version
              }}</span>
            </div>
          </template>
        </Column>
        <Column field="description" :header="$t('description')" :sortable="true">
          <template #body="{ data }">
            <div class="flex justify-start">
              <span data-cy="system-update-description" class="text-small">{{
                data.description
              }}</span>
            </div>
          </template>
        </Column>
        <Column
          field="created_at"
          :header="$t('createdAt')"
          :sortable="true"
          style="flex: 0 0 0"
          headerStyle="minWidth: 150px"
          bodyStyle="minWidth: 150px"
        >
          <template #body="{ data }">
            <span v-if="data.created_at" data-cy="update-created-at" class="text-small">{{
              dayjs(data.created_at).format('ll')
            }}</span>
            <span v-else class="empty">--</span>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';

import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useUpdatesStore } from '@/stores/admin/updates/updates.store';

import type { PropType } from 'vue';
import type { Update } from '@/models/updates.model';

import dayjs from 'dayjs';

export default {
  name: 'UpdatesPicker',
  components: {
    DataTable,
    Column,
    InputText,
    // eslint-disable-next-line vue/no-unused-components
    RadioButton,
  },

  props: {
    productType: {
      type: String as PropType<string>,
      required: true,
    },
  },

  setup(props, { emit }) {
    const updatesStore = useUpdatesStore();
    const { systemUpdates } = storeToRefs(updatesStore);
    const loading = ref(true);
    const selectedSystemUpdate = ref<Update | null>(null);

    const onSelectedSystemUpdate = (event: any) => {
      emit('onSelectedSystemUpdate', event.data.id);
    };

    const matchModeOptions = ref([{ label: 'Starts With', value: FilterMatchMode.STARTS_WITH }]);

    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    onMounted(async () => {
      await updatesStore
        .fetchSystemUpdatesList(props.productType)
        .then(() => (loading.value = false));
    });

    return {
      filters,
      matchModeOptions,
      loading,
      selectedSystemUpdate,
      onSelectedSystemUpdate,
      systemUpdates,
      dayjs,
    };
  },
};
</script>

<style lang="scss" scoped>
:deep(.p-datatable .p-sortable-column .p-sortable-column-icon) {
  margin-left: 10px;
}
</style>
