import { defineStore } from 'pinia';
import type { ProductType } from '@/models/productTypes.model';
import { fetchProductTypes } from '@/stores/admin/productTypes/productTypes.api';

interface ProductTypesState {
  productTypes: ProductType[];
}

export const useProductTypesStore = defineStore('productTypes', {
  state: (): ProductTypesState => ({
    productTypes: [] as ProductType[],
  }),
  actions: {
    async fetchProductTypesList() {
      return await fetchProductTypes()
        .then((response) => {
          this.productTypes = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },
  },
});
