<template>
  <div class="map-container">
    <l-map
      data-cy="charge-park-map"
      ref="map"
      :use-global-leaflet="false"
      :zoom="zoom"
      :center="[Number(mapCenter.latitude), Number(mapCenter.longitude)]"
      :options="options"
      @click="onMapClick"
    >
      <l-control-attribution position="bottomright" prefix="Leaflet | OpenStreetMap" />
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        layer-type="base"
        name="OpenStreetMap"
      />
      <l-marker
        :visible="!!location.latitude && !!location.longitude"
        :lat-lng="[Number(location.latitude), Number(location.longitude)]"
      ></l-marker>
    </l-map>
    <div class="text-sm text-grey my-3">
      <i class="pi pi-info-circle text-xs" />
      <span class="pl-1">{{ $t('mapInfo') }}</span>
    </div>
  </div>
  <div class="flex flex-wrap mt-8">
    <div class="flex mb-2">
      <div class="field flex flex-col mt-2 mr-2 coordinate">
        <label for="latitude" class="font-medium mb-2">{{ $t('chargePark.latitude') }}</label>
        <InputText
          data-cy="latitude-input"
          id="chargeParkLatitude"
          type="text"
          aria-describedby="latitude-help"
          class="p-inputtext-sm"
          v-model="transformedLatitude"
          :placeholder="$t('chargePark.latitude')"
          @keyup="validateLatitude"
        />
        <div class="input-errors" v-for="(error, index) of v$.latitude.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
      <div class="field flex flex-col mt-2 coordinate">
        <label for="longitude" class="font-medium mb-2">{{ $t('chargePark.longitude') }}</label>
        <InputText
          data-cy="longitude-input"
          id="chargeParkLongitude"
          type="text"
          aria-describedby="longitude-help"
          class="p-inputtext-sm"
          v-model="transformedLongitude"
          :placeholder="$t('chargePark.longitude')"
          @keyup="validateLongitude"
        />
        <div class="input-errors" v-for="(error, index) of v$.longitude.$errors" :key="index">
          <small class="p-error">{{ error.$message }}</small>
        </div>
      </div>
    </div>
    <div class="mx-4 pt-12">{{ $t('or') }}</div>
    <Button
      data-cy="lookup-from-address-button"
      :label="$t('lookupFromAddress')"
      class="p-button-white whitespace-nowrap self-start mb-3 mt-10"
      :disabled="!addressEntered"
      :loading="loading"
      @click="getGeoLocation"
    />
  </div>
  <div class="w-full border-t border-t-[var--gray-200] my-4"></div>
  <div class="field flex justify-between mt-2">
    <label for="chargeParkAddress" class="font-medium mb-2"
      >{{ $t('address') }}<span class="required font-medium">*</span></label
    >
    <div class="flex flex-col w-1/2">
      <Textarea
        data-cy="location-address-input"
        class="border border-[var--gray-200] rounded p-2 w-full"
        v-model="location.address"
        :placeholder="$t('address')"
        autoResize
        row="1"
        cols="18"
        @keyup="validateAddress"
      />
      <div class="input-errors" v-for="(error, index) of v$.address.$errors" :key="index">
        <small class="p-error">{{ error.$message }}</small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { LMap, LTileLayer, LControlAttribution, LMarker } from '@vue-leaflet/vue-leaflet';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE, MAX_LENGTH } from '@/utils/constants';
import { useVuelidate } from '@vuelidate/core';
import { useI18n } from 'vue-i18n';
import { latitude, longitude, asciiCharactersWithNewLine } from '@/utils/validationRules';
import { helpers, maxLength } from '@vuelidate/validators';
import { useLocationStore } from '@/stores/admin/location/location.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';

import 'leaflet/dist/leaflet.css';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';

import type { MapOptions } from 'leaflet';
import type { Location } from '@/models/location.model';

const props = defineProps<{ address: string; resetValidation: boolean }>();
const { t } = useI18n();
const locationStore = useLocationStore();
const chargeParkStore = useChargeParksStore();
const { chargeParkEditionData, chargeParkIsEditing } = storeToRefs(chargeParkStore);
const loading = ref(false);
const emit = defineEmits([
  'coordinates',
  'onValidateAddress',
  'onValidateLatitude',
  'onValidateLongitude',
]);

const location = ref<Location>({
  address: props.address,
  latitude: chargeParkEditionData.value.latitude ? chargeParkEditionData.value.latitude : '',
  longitude: chargeParkEditionData.value.longitude ? chargeParkEditionData.value.longitude : '',
});

const mapCenter = ref({
  latitude: chargeParkEditionData.value.latitude
    ? chargeParkEditionData.value.latitude
    : DEFAULT_LATITUDE,
  longitude: chargeParkEditionData.value.longitude
    ? chargeParkEditionData.value.longitude
    : DEFAULT_LONGITUDE,
});
const isMarkerVisible = ref(false);
const map = ref<typeof LMap>();
const addressEntered = ref(false);

const transformedLatitude = computed({
  get: () => String(location.value.latitude),
  set: (v) => (location.value.latitude = v.toString().replace(',', '.')),
});

const transformedLongitude = computed({
  get: () => String(location.value.longitude),
  set: (v) => (location.value.longitude = v.toString().replace(',', '.')),
});

const options: MapOptions = {
  attributionControl: false,
};

const zoom = ref(5);

const onMapClick = (event: MouseEvent) => {
  const coordinates = map.value?.leafletObject.mouseEventToLatLng(event);

  location.value.latitude = coordinates.lat.toFixed(14);
  location.value.longitude = coordinates.lng.toFixed(14);

  v$.value.latitude.$touch();
  v$.value.longitude.$touch();

  isMarkerVisible.value = true;

  emit('coordinates', coordinates.lat.toFixed(14), coordinates.lng.toFixed(14), v$.value);
};

const getGeoLocation = async () => {
  loading.value = true;

  await locationStore
    .fetchLocation(props.address)
    .then(({ data }) => {
      loading.value = false;

      location.value.latitude = data.latitude.toFixed(14).toString();
      location.value.longitude = data.longitude.toFixed(14).toString();
      mapCenter.value.latitude = data.latitude.toFixed(14).toString();
      mapCenter.value.longitude = data.longitude.toFixed(14).toString();

      emit('coordinates', data.latitude.toFixed(14), data.longitude.toFixed(14), v$.value);

      isMarkerVisible.value = true;
    })
    .catch((error) => {
      loading.value = false;
      throw new Error(error);
    });
};

watch(
  () => props.resetValidation,
  (value) => {
    if (value) {
      v$.value.address.$reset();
      v$.value.latitude.$reset();
      v$.value.longitude.$reset();
      v$.value.$reset();
    }
  }
);

watch(
  () => location.value.latitude,
  (value) => {
    if ((value !== '' || !value) && chargeParkIsEditing.value) {
      mapCenter.value.latitude = location.value.latitude;
      mapCenter.value.longitude = location.value.longitude;
    }
  },
  { immediate: true }
);

watch(
  () => location.value.address,
  (value) => {
    if (value !== '' && value !== null) {
      addressEntered.value = true;
    } else {
      addressEntered.value = false;
    }
  },
  { immediate: true }
);

const validateAddress = async () => {
  await v$.value.address.$validate();
  emit('onValidateAddress', location.value.address, v$.value);
};

const validateLatitude = async () => {
  await v$.value.latitude.$validate();
  emit('onValidateLatitude', location.value.latitude, v$.value);
};

const validateLongitude = async () => {
  await v$.value.longitude.$validate();
  emit('onValidateLongitude', location.value.longitude, v$.value);
};

const rules = computed(() => {
  return {
    address: {
      asciiCharactersWithNewLine: helpers.withMessage(
        t('rules.asciiCharactersWithNewLine', { field: t('address') }),
        asciiCharactersWithNewLine
      ),
      maxLength: maxLength(MAX_LENGTH),
    },
    latitude: {
      latitude: helpers.withMessage(
        t('rules.coordinate', {
          field: t('chargePark.latitude'),
          start: -90,
          end: 90,
        }),
        latitude
      ),
    },
    longitude: {
      longitude: helpers.withMessage(
        t('rules.coordinate', {
          field: t('chargePark.longitude'),
          start: -180,
          end: 180,
        }),
        longitude
      ),
    },
  };
});
const v$ = useVuelidate(rules, location.value);
</script>

<style lang="scss" scoped>
.map-container {
  height: 192px;
  width: auto;
  margin-top: 15px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 4px;
}

.input-errors {
  line-height: 16px;
}

.coordinate {
  width: 160px;
}
</style>
