<template>
  <div class="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 general">
    <HardwareSystemInfo :system="props.system" @refetch-system="emit('refetchSystem')" v-if="!isLoading" />
    <HardwareSystemLocation :system="props.system" @refetch-system="emit('refetchSystem')" class="" v-if="!isLoading" />
    <HardwareSystemContact :system="props.system" @refetch-system="emit('refetchSystem')" v-if="!isLoading" />
    <HardwareSystemCombinedInfo :system="props.system" @refetch-system="emit('refetchSystem')" v-if="!isLoading" @configurePressed="emit('configureSystemComponentsPressed')" /> 
  </div>
</template>
<script setup lang="ts">
import { ref, watch } from 'vue';

import HardwareSystemInfo from './info/HardwareSystemInfo.vue';
import HardwareSystemContact from './contact/HardwareSystemContact.vue';
import HardwareSystemLocation from './location/HardwareSystemLocation.vue';
import HardwareSystemCombinedInfo from './info/HardwareSystemCombinedInfo.vue';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
const isLoading = ref(true);
const props = defineProps<{
  system: HardwareSystem;
}>();

const emit = defineEmits(["refetchSystem", "configureSystemComponentsPressed"])
watch(
  props,
  (value) => {
    props.system ? (isLoading.value = false) : (isLoading.value = true);
  },
  { immediate: true, deep: true }
);
</script>
<style scoped lang="scss">
.general {
  background-color: $grey-light;
  padding: 20px;
}
</style>
