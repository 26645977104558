<template>
  <Dialog
    v-model:visible="eventDetailsModalIsOpen"
    data-cy="event-history-details-modal"
    modal
    class="event-history-details bg-gray-100"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '820px' }"
    :header="t('history.eventDetails')"
    @hide="closeModal"
  >
    <div class="flex px-12 border-b text-sm pt-2 pb-1">
      <div class="w-full info-single">
        <ReadOnlyItem
          :name="$t('hardwareSystem.assetName')"
          :value="props.system.name"
          :valueID="'hardwareSystem.assetName'"
        />
        <ReadOnlyItem
          :name="$t('hardwareSystem.assetType')"
          :value="props.system.product_type_name"
          :valueID="'hardwareSystem.assetType'"
        />
      </div>
    </div>
    <div class="flex px-12 border-b text-sm pt-2 pb-1">
      <div class="w-full info-single">
        <ReadOnlyItem :name="$t('time')"  >
          <date-time-display id="time" :date="eventDetails?.created_at" bold />
        </ReadOnlyItem>
        <ReadOnlyItem
          :name="$t('type')"
          :value="eventDetails?.type ? eventDetails?.type : '--'"
          :valueID="'type'"
        />
        <ReadOnlyItem
          :name="$t('history.title')"
          :value="eventDetails?.event ? eventDetails?.event : '--'"
          :valueID="'history.title'"
        />
      </div>
    </div>
    <div class="flex px-12 text-sm pt-2 pb-2">
      <div class="w-full info-single">
        <div v-for="(item, index) in eventDetails?.details" :key="index">
          <div v-if="!isObject(item.content) && !isArray(item.content)">
            <ReadOnlyItem
              :name="item.tag"
              :value="item.content ? item.content : '--'"
              :valueID="item.tag"
            />
          </div>
          <div v-else>
            <div v-for="(value, key) in item.content" :key="key">
              <div v-if="!isObject(value) && !isArray(value)">
                <ReadOnlyItem
                  :name="key"
                  :value="value ? value : '--'"
                    :valueID="String(key)"
                />
              </div>
              <div v-else>
                <ReadOnlyItem :name="key" :valueID="String(key)" />
                <div v-for="(nestedValue, nestedKey) in value" :key="nestedKey">
                  <ReadOnlyItem
                    v-if="!isObject(nestedValue) && !isArray(nestedValue)"
                    :name="nestedKey"
                    :value="nestedValue"
                    :valueID="String(nestedKey)"
                    connector
                  />
                  <ReadOnlyItem
                    v-if="isObject(nestedValue) && !isArray(nestedValue)"
                    :name="nestedKey"
                    :valueID="String(nestedKey)"
                    connector
                  />
                  <div v-if="isObject(nestedValue) && !isArray(nestedValue)">
                    <div
                      v-for="(secondNestedValue, secondNestedKey, index) in nestedValue"
                      :key="index"
                    >
                      <ReadOnlyItem
                        v-if="index! <= Object.keys(nestedValue).length - 2"
                        :name="secondNestedKey"
                        :value="secondNestedValue ? secondNestedValue : '--'"
                        :valueID="String(secondNestedKey)"
                        nested
                      />
                      <ReadOnlyItem
                        v-if="index! === Object.keys(nestedValue).length - 1"
                        :name="secondNestedKey"
                        :value="secondNestedValue ? secondNestedValue : '--'"
                        :valueID="String(secondNestedKey)"
                        :class="'pl-[25px]'"
                        connector
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="aggInfo && aggInfo.Count">
          <ReadOnlyItem
            :name="$t('history.totalCount')"
            :valueID="'history.totalCount'"
            :value="aggInfo.Count"
          />
        </div>
      </div>
    </div>

    <EventHistoryDetailsAggregatedErrorsTable
      v-if="aggInfo && aggInfo.MessageDetails"
      class="px-2 border-t"
      :modelValue="aggInfo.MessageDetails"
    />

    <template #footer>
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white mx-auto mb-2 block mt-5"
        data-cy="event-history-details-cancel-button"
        @click="closeModal"
      />
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import ReadOnlyItem from '@/components/common/readOnly/ReadOnlyItem.vue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import EventHistoryDetailsAggregatedErrorsTable from './EventHistoryDetailsAggregatedErrorsTable.vue';
import { computed } from 'vue';
import type { HistoryAggregation } from '@/models/history.model';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
const { t } = useI18n();
const systemStore = useHardwareSystemsStore();
const { eventDetails, eventDetailsModalIsOpen } = storeToRefs(systemStore);
const props = defineProps<{
  system: HardwareSystem;
}>(); 

const closeModal = () => {
  eventDetailsModalIsOpen.value = false;
};

const aggInfo = computed(() => {
  const typedDetails = eventDetails.value as HistoryAggregation;
  return typedDetails?.aggregated_information;
});
</script>
