import { defineStore } from 'pinia';
import type { Update, UpdateNew, UpdateEdit, UpdateEditData } from '@/models/updates.model';
import {
  fetchUpdates,
  uploadUpdateFile,
  uploadUpdate,
  editUpdate,
  fetchSystemUpdates,
  deleteUpdate,
  fetchUpdateFilePath,
} from '@/stores/admin/updates/updates.api';

interface UpdatesState {
  updates: Update[];
  uploadUpdatesModalIsOpen: boolean;
  systemUpdates: [];
  uploadIsEditing: boolean;
  uploadEditionData: UpdateEdit;
  updateDeleteModalIsOpen: boolean;
  updateDeleteData: {
    id: number;
  };
}

export const useUpdatesStore = defineStore('updates', {
  state: (): UpdatesState => ({
    updates: [],
    uploadUpdatesModalIsOpen: false,
    systemUpdates: [],
    uploadIsEditing: false,
    uploadEditionData: {
      id: 0,
      file_name: '',
      description: '',
      version: '',
      product_type_id: 0,
      product_type_name: '',
    },
    updateDeleteModalIsOpen: false,
    updateDeleteData: {
      id: 0,
    },
  }),
  actions: {
    async fetchUpdatesList() {
      return await fetchUpdates()
        .then((response) => {
          this.updates = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async uploadFile(file: File) {
      return await uploadUpdateFile(file)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async uploadUpdateData(data: UpdateNew) {
      return await uploadUpdate(data)
        .then((response) => {
          this.fetchUpdatesList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async editUpdateData(data: UpdateEditData) {
      return await editUpdate(data)
        .then((response) => {
          this.fetchUpdatesList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchSystemUpdatesList(productTypeName: string) {
      return await fetchSystemUpdates(productTypeName)
        .then((response) => {
          this.systemUpdates = response.data;
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async deleteFirmwareUpdate(id: number) {
      return await deleteUpdate(id)
        .then((response) => {
          this.fetchUpdatesList();
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    async fetchUpdateFileFullPath(url: string) {
      return await fetchUpdateFilePath(url)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    resetUpdateEditionData() {
      this.uploadEditionData = {
        id: 0,
        file_name: '',
        description: '',
        version: '',
        product_type_id: 0,
        product_type_name: '',
      };
    },
  },
});
