<template>
  <div v-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <error-bar v-else-if="error" title="Failed to load control panel" :message="error" />
  <control-panel-panels
    v-else-if="dashboard && system"
    :dashboard-id="system.default_dashboard_id"
    :system="system"
    :panels="dashboard.content.panels"
    activePanelId="status"
  />
</template>

<script setup lang="ts">
import ControlPanelPanels from '@/components/hardwareSystems/controlPanel/ControlPanelPanels.vue';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { Dashboard } from '@/models/controlPanel/dashboard';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
import { onMounted, ref } from 'vue';
import { fetchDashboard } from '@/stores/admin/controlPanel/controlPanel.api';

const props = defineProps<{
  system: HardwareSystem | null;
}>();

const dashboard = ref<Dashboard | null>(null);
const loading = ref<boolean>(true);
const error = ref<string | null>(null);

async function getDashboard() {
  if (!props.system) return;

  loading.value = true;
  error.value = null;

  await fetchDashboard(props.system.id)
    .then((response) => (dashboard.value = response))
    .catch((e) => (error.value = e.message))
    .finally(() => (loading.value = false));
}

onMounted(() => {
  getDashboard();
});
</script>

<style scoped lang="scss"></style>
