import type {
  BrandingTemplate,
  Option,
  WriteableBrandingConfiguration,
} from '@/stores/admin/branding/branding.types';

export type InformationValidationErrors = {
  name?: string | null;
  productTypeName?: string | null;
};

export type ConfigurationValidationErrors = {
  [groupId: string]: {
    [settingId: string]: string | null;
  };
};

export type SiteValidationErrors = {
  siteIds?: string | null;
};

type Validator = (value: any) => string | null;

const required: Validator = (value: string | any[]) =>
  value === undefined || value === null ? 'Required' : null;
const notEmpty: Validator = (value: string | any[]) =>
  value === undefined || value === null || value.length === 0 ? 'Required' : null;
const validColor: Validator = (value: string) =>
  value && !/^#([0-9a-f]{3}){1,2}$/i.test(value) ? 'Must be a valid color' : null;
const validPhone: Validator = (value: string) =>
  value && !/^\+?[0-9]{10,14}$/i.test(value) ? 'Must be a valid phone number' : null;
const validEmail: Validator = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? 'Must be a valid email address'
    : null;

export const urlRegexPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
const validUrl: Validator = (value: string) =>
  value && !urlRegexPattern.test(value) ? 'Must be a valid URL' : null;

function evaluate(value: unknown, validators: Validator[]): string | null {
  const errors = validators.map((validator) => validator(value)).filter((error) => error !== null);
  return errors.length > 0 ? errors[0] : null;
}

const validatorMap: Partial<Record<Option['type'], Validator[]>> = {
  color: [validColor],
  phone: [validPhone],
  email: [validEmail],
  url: [validUrl],
  string: [required],
};

export type ValuesAsUnknown<T> = {
  [K in keyof T]: T[K] extends string | number | boolean ? unknown : ValuesAsUnknown<T[K]>;
};

export function validateInformation(
  name: Partial<ValuesAsUnknown<WriteableBrandingConfiguration>>['name'],
  productTypeName: Partial<ValuesAsUnknown<WriteableBrandingConfiguration>>['product_type_name']
): InformationValidationErrors {
  const errors: InformationValidationErrors = {};

  errors.name = evaluate(name, [required, notEmpty]);
  errors.productTypeName = evaluate(productTypeName, [required]);

  return errors;
}

export function validateBranding(
  template: BrandingTemplate,
  settings: Partial<ValuesAsUnknown<WriteableBrandingConfiguration>>['settings']
): ConfigurationValidationErrors {
  const errors: ConfigurationValidationErrors = {};
  if (!settings) return errors;

  for (const group of template.settings) {
    errors[group.id] = {};
    for (const setting of group.settings) {
      const validators = validatorMap[setting.type] || [];
      errors[group.id][setting.id] = evaluate(settings[group.id]?.[setting.id], validators);
    }
  }

  return errors;
}

export function validateSites(
  siteIds: Partial<ValuesAsUnknown<WriteableBrandingConfiguration>>['charge_park_ids']
): SiteValidationErrors {
  return {};
}
