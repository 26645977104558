import type {
  BarChartWidget,
  MapWidget,
  Panel,
  PieChartWidget,
  TableWidget,
  TransitionLayer,
  WidgetStack,
} from './dashboard.types';
import { WidgetSize } from './dashboard.types';

import fleetConnectionStatus from '@/data/widgets/fleet-connection-status-pie-widget.json';
import fleetConnectorStatus from '@/data/widgets/fleet-connector-status-pie-widget.json';

import mapFleetConnectionStatusBySystem from '@/data/widgets/fleet-connection-by-system-map-widget.json';
import energyPerSystem from '@/data/widgets/energy-per-system-last-week-bar-widget.json';
import energyPerChargePark from '@/data/widgets/energy-per-chargepark-last-week-bar-widget.json';
import energyPerDay from '@/data/widgets/energy-per-day-bar-widget.json';
import setChargeParkTransitionLayer from '@/data/widgets/set-chargepark-transition-layer.json';

const exampleWidgetStack: WidgetStack = {
  id: 20,
  type: 'stack',
  presentation: {},
  stack: [
    energyPerChargePark as BarChartWidget,
    setChargeParkTransitionLayer as TransitionLayer,
    energyPerSystem as BarChartWidget,
  ],
};

const exampleTableWidget: TableWidget = {
  id: 'some-table-widget',
  type: 'table',
  title: 'Charger performance & utilization',
  presentation: { size: WidgetSize.LARGE },
  axis: {
    x: { label: 'System ID', dimension: 'system.id' },
  },
  columns: [
    {
      label: 'Energy Delivered',
      overrideSourceUnit: 'kWh',
      query: {
        series: {
          productType: 'DirectPower',
          path: 'functions.chargePoints.chargePoint0:46',
        },
        transform: {
          aggregation: 'sum',
          last: false,
          groupBy: [
            {
              target: 'system.id',
            },
          ],
          orderBy: [],
          filters: [
            {
              dimension: 'time',
              condition: '>=',
              value: '-1w',
            },
          ],
        },
      },
    },
    {
      label: 'Max Power',
      overrideSourceUnit: 'W',
      desiredUnit: 'kW',
      query: {
        series: {
          productType: 'DirectPower',
          path: 'functions.chargePoints.chargePoint0:44',
        },
        transform: {
          aggregation: 'max',
          last: false,
          groupBy: [
            {
              target: 'system.id',
            },
          ],
          orderBy: [],
          filters: [
            {
              dimension: 'time',
              condition: '>=',
              value: '-1w',
            },
          ],
        },
      },
    },
    {
      label: 'Uptime',
      overrideSourceUnit: '%',
      query: {
        series: {
          productType: 'DirectPower',
          path: 'platform.uptime:uptime_percent',
        },
        transform: {
          aggregation: 'avg',
          last: false,
          groupBy: [
            {
              target: 'system.id',
            },
          ],
          orderBy: [],
          filters: [
            {
              dimension: 'time',
              condition: '>=',
              value: '-1m',
            },
          ],
        },
      },
    },
    {
      label: 'Offline',
      overrideSourceUnit: '%',
      query: {
        series: {
          productType: 'DirectPower',
          path: 'platform.uptime:non_overlapping_outage_percent',
        },
        transform: {
          aggregation: 'avg',
          last: false,
          groupBy: [
            {
              target: 'system.id',
            },
          ],
          orderBy: [],
          filters: [
            {
              dimension: 'time',
              condition: '>=',
              value: '-1m',
            },
          ],
        },
      },
    },
    {
      label: 'In use',
      overrideSourceUnit: 'min',
      desiredUnit: 'hours',
      query: {
        series: {
          productType: 'DirectPower',
          path: 'platform.chargingSessions:duration',
        },
        transform: {
          aggregation: 'sum',
          last: false,
          groupBy: [
            {
              target: 'system.id',
            },
          ],
          orderBy: [],
          filters: [
            {
              dimension: 'time',
              condition: '>=',
              value: '-1m',
            },
          ],
        },
      },
    },
    {
      label: 'Number Of Charging Sessions',
      query: {
        series: {
          productType: 'DirectPower',
          path: 'platform.chargingSessions:duration',
        },
        transform: {
          aggregation: 'count',
          last: false,
          groupBy: [
            {
              target: 'system.id',
            },
          ],
          orderBy: [],
          filters: [
            {
              dimension: 'time',
              condition: '>=',
              value: '-1m',
            },
          ],
        },
      },
    },
    {
      label: 'Average Charging Session Duration',
      overrideSourceUnit: 'min',
      query: {
        series: {
          productType: 'DirectPower',
          path: 'platform.chargingSessions:duration',
        },
        transform: {
          aggregation: 'avg',
          last: false,
          groupBy: [
            {
              target: 'system.id',
            },
          ],
          orderBy: [],
          filters: [
            {
              dimension: 'time',
              condition: '>=',
              value: '-1m',
            },
          ],
        },
      },
    },
  ],
};

export const examplePanel: Panel = {
  id: '1',
  title: 'Example Panel',
  widgets: [
    fleetConnectionStatus as PieChartWidget,
    fleetConnectorStatus as PieChartWidget,
    
    exampleWidgetStack as WidgetStack,
    energyPerDay as BarChartWidget,
    
    mapFleetConnectionStatusBySystem as MapWidget,
    exampleTableWidget,
  ],
};
