<template>
  <div class="w-20 h-20 flex items-center justify-center">
    <img
      data-cy="media-library-thumbnail"
      :src="thumbnailUrl"
      :alt="media.file_name"
      class="object-contain rounded-md aspect-auto max-w-[80px] max-h-20 overflow-hidden text-xs"
    />
  </div>
</template>

<script setup lang="ts">
import type { Media } from '@/models/media.model';
import { computed } from 'vue';

const props = defineProps<{
  media: Media;
}>();

const thumbnailUrl = computed(() => {
  if (props.media.thumbnail) return props.media.thumbnail;

  if (props.media.file_type.toLowerCase() === 'image') return '/images/image-placeholder.jpg';

  return '/images/video-placeholder.jpg';
});
</script>
