<template>
  <div v-if="allRolesAdded" class="text-center my-8">
    <span data-cy="all-roles-added">{{ $t('user.roles.allAdded') }}</span>
  </div>
  <div
    v-else
    data-cy="roles-picker-table"
    :class="`picker-table roles-picker-table pt-2 px-12 ${customClass}`"
  >
    <span class="block title font-medium field-edit-label"
      >{{ title }}<span class="required">*</span></span
    >
    <span class="block text-grey text-sm mb-2">{{ $t('user.role.info') }}</span>
    <div class="container">
      <div v-if="loading" class="loader-wrapper loader-wrapper-sm">
        <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
      </div>
      <DataTable
        v-if="!loading"
        :value="rolesList"
        dataKey="id"
        class="p-datatable-sm user-role-picker"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['name', 'description']"
        v-model:selection="selectedRoles"
        @rowSelect="onSelectedRoles"
        @rowUnselect="onUnselectedRoles"
        @rowSelectAll="onSelectedAllRoles"
        @rowUnselectAll="onUnselectedAllRoles"
        :metaKeySelection="false"
      >
        <template #empty>{{ $t('user.role.empty') }}</template>
        <template #loading>{{ $t('user.role.loading') }}</template>
        <template #header>
          <div class="flex justify-between align-items-center">
            <span class="p-input-icon-left search">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                :placeholder="$t('searchBy', { name: `${$t('name')}, ${$t('description')}` })"
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </template>
        <Column
          selectionMode="multiple"
          style="flex: 0 0 0; padding: 16px"
          bodyStyle="padding-left: 16px"
        >
        </Column>
        <Column field="name" :header="$t('name')">
          <template #body="{ data }">
            <div class="w-full pl-1">
              <span :id="data.id" class="text-sm font-medium" data-cy="role-name">{{
                data.name
              }}</span>
            </div>
          </template>
        </Column>
        <Column field="description" :header="$t('description')">
          <template #body="{ data }">
            <span v-if="data.description" data-cy="role-description" class="text-small">{{
              data.description
            }}</span>
            <span v-else class="empty">--</span>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { FilterMatchMode } from 'primevue/api';
import { useUsersStore } from '@/stores/admin/users/users.store';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';

import type { UserRole, UserRoleId } from '@/models/users.model';

const props = defineProps<{
  organisationRoles: UserRole[] | [];
  id?: number | null;
  title: string;
  value?: UserRoleId[] | [];
  customClass?: string;
}>();

const emit = defineEmits([
  'onRolesSelect',
  'onRolesUnselect',
  'onRolesSelectAll',
  'onUnselectedAllRoles',
]);

const userStore = useUsersStore();
const { roles } = storeToRefs(userStore);
const loading = ref(true);
const selectedRoles = ref<UserRoleId[]>(props.value || []);
const rolesList = ref();
const allRolesAdded = computed(() => props.id && roles.value.length === 0 && !loading.value);

const onSelectedRoles = (event: any) => {
  emit('onRolesSelect', { id: event.data.id });
};

const onUnselectedRoles = (event: any) => {
  emit('onRolesUnselect', { id: event.data.id });
};

const onSelectedAllRoles = (event: any) => {
  const ids = event.data.map((item: any) => ({ id: item.id }));
  emit('onRolesSelectAll', ids);
};

const onUnselectedAllRoles = () => {
  emit('onUnselectedAllRoles');
};

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

onMounted(async () => {
  await userStore.fetchUserRoles().then(() => {
    rolesList.value = roles.value.filter((role) =>
      props.organisationRoles.some((orgRole: UserRole) => role.id === orgRole.id)
    );
    loading.value = false;
  });
});
</script>

<style lang="scss" scoped>
.container {
  border: none;
}

:deep(.p-datatable) {
  border: 1px solid $grey-medium-light;
  border-radius: 4px;
}
</style>
