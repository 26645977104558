<template>
  <div class="control-container">
    <div class="control-panel-header">
      <div v-if="!props.activePanelId" class="control-panel-tabs">
        <div
          v-for="panel in props.panels"
          :key="panel.id"
          class="tab"
          :class="{ active: panel.id === activePanelId }"
          :data-cy="`sub-tab-${panel.id}`"
          @click="setActivePanelId(panel.id)"
        >
          <p>{{ panel.label }}</p>
        </div>
      </div>

      <div class="status-container" title="Data Status">
        <i class="pi pi-spin pi-spinner" v-if="status === 'loading'" />
        <check-circle-icon style="height: 1rem" v-else-if="status === 'success'" class="success" />
        <exclamation-triangle-icon
          style="height: 1rem"
          v-else-if="status === 'error'"
          class="error"
        />
      </div>
    </div>

    <div v-if="activePanel === null" class="no-data">
      <p>No panels defined</p>
    </div>

    <div v-else v-for="(row, rowIndex) in activePanel.rows" :key="rowIndex" class="row">
      <div
        v-for="(column, colIndex) in row.columns"
        :key="colIndex"
        class="column"
        :style="{ gridColumnEnd: `span ${column.width}` }"
      >
        <template v-for="card in column.cards" :key="card.id">
          <widget-card
            v-if="card.widgets && card.widgets?.length > 0"
            :data-cy="`card-${card.id}`"
            :system-id="props.system.id"
            :card="card"
            :load-status="status"
            :unit-system="me.units"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import WidgetCard from '@/components/hardwareSystems/controlPanel/widgets/common/WidgetCard.vue';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import type { Panel } from '@/models/controlPanel/dashboard';
import { createControlPanelContext, filterWidgets } from '@/logic/controlPanel/widgets';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { data, type Events } from '@/logic/controlPanel/eventbus';
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid';

const props = defineProps<{
  dashboardId?: number;
  system: HardwareSystem;
  panels: Panel[];
  activePanelId?: string;
}>();

const { me } = useUsersStore();
const controlPanelStore = useControlPanelStore();

const activePanelId = ref(props.activePanelId ?? props.panels[0]?.id ?? null);

const controlPanelContext = computed(() => createControlPanelContext(props.system));

const status = ref<Events['status']['state']>('loading');

const activePanel = computed<Panel | null>(() => {
  if (!activePanelId.value) return null;
  const panel = props.panels.find((panel) => panel.id === activePanelId.value);
  if (!panel) return null;

  return {
    id: panel.id,
    label: panel.label,
    params: panel.params,
    rows: panel.rows.map((row) => ({
      columns: row.columns.map((column) => ({
        cards: column.cards.map((card) => ({
          id: card.id,
          title: card.title,
          parameters: card.parameters,
          widgets: filterWidgets(controlPanelContext.value, card.widgets || []),
        })),
        width: column.width,
      })),
    })),
  };
});

function setActivePanelId(panelId: string) {
  activePanelId.value = panelId;
}

onMounted(() => {
  controlPanelStore.initialize(props.system.id);
  controlPanelStore.startPolling(props.dashboardId, activePanelId.value ?? undefined);
});

function onStatusEvent(newStatus: Events['status']) {
  status.value = newStatus.state;
}

onMounted(() => data.on('status', onStatusEvent));
onUnmounted(() => data.off('status', onStatusEvent));
</script>

<style scoped lang="scss">
.control-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  padding: 1rem;
  gap: 1rem;

  background-image: radial-gradient(#c9c9c9, transparent 5%);
  background-size: 40px 40px;
  background-position: 0 0, 50px 50px;
}

.control-panel-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: top;
}

.control-panel-tabs {
  display: flex;
  justify-content: flex-start;
  margin-right: auto;

  .tab {
    padding: 0.5rem 1rem;
    border: 1px solid var(--gray-200);
    background-color: var(--gray-0);
    cursor: pointer;

    &:first-child {
      border-radius: var(--rounded-md) 0 0 var(--rounded-md);
    }

    &:last-child {
      border-radius: 0 var(--rounded-md) var(--rounded-md) 0;
    }

    &:hover:not(.active) {
      background-color: var(--gray-50);
    }

    &.active {
      color: var(--gray-0);
      background-color: var(--green-primary);
    }

    &:not(:last-child) {
      border-right: 1px solid var(--gray-200);
    }
  }
}

.row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 1rem;
  min-width: 1100px;

  box-sizing: border-box;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.2rem;
  width: 2.2rem;
  padding: 0.5rem;
  font-size: 1rem;
  line-height: 1rem;
  color: var(--gray-400);
  border: 1px solid var(--gray-200);
  background-color: var(--gray-0);
  border-radius: var(--rounded-md);
  margin-left: auto;

  .success {
    color: var(--green-primary);
  }

  .error {
    color: var(--red-primary);
  }
}
</style>
