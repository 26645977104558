<template>
  <div v-if="systemsInTable.length > 0">
    <PanelWithEdit
      :title="isParentSystem ? $t('hardwareSystem.parentSystem'):$t('hardwareSystem.systemComponents')"
      :loading="isLoading"
      :editBtn="false"
    >
    <template #header>
        <Button
          :label="isParentSystem ? $t('show'):$t('dialogSteps.configuration')"
          icon="pi pi-external-link"
          class="p-button-outlined p-button-white p-button-xs" 
          @click="isParentSystem ? showSystem(system.combined_system_setup.parent?.id) :emit('configurePressed')"
        />
      </template>
      <div v-if="isParentSystem" class="flex flex-col w-full">
    <div v-for="(value, key) in parentSystemDetails" :key="key">
      <div class="flex justify-between">
        <p data-cy="system-info-field-name" class="font-medium py-3 whitespace-nowrap">
          {{ key === 'name' ? $t('name') : $t(`hardwareSystem.hardwareSystemDetails.${key}`) }}
        </p>
        <div
          v-if="value"
          data-cy="system-info-field-value"
          :class="`py-3 pl-4 w-2/3 text-right font-medium ${
            key === 'nidec_id' 
              ? 'text-[var(--gray-400)]'
              : 'text-[var(--gray-700)]'
          }`"
        > 
          <span >{{ value }}</span>
        </div>
        <span v-if="!value" class="empty py-2 px-4 font-medium">--</span>
      </div> 
    </div>
  </div>

      <DataTable
        v-if="!isParentSystem"
        selectionMode="single"
        @row-click="onRowSelect"
        data-cy="alert-history-table"
        :value="systemsInTable"
        :paginator="true"
        :alwaysShowPaginator="false"
        :rows="10"
        dataKey="id"
        class="p-datatable-sm mb-5 mt-6 border-rounded overflow-hidden alert-history-table"
      >
        <Column
          class="no-top-border-row"
          field="product_type_name"
          :header="$t('update.productTypeName')"
          :sortable="true"
        >
          <template #body="{ data }">
            <div v-if="isLoading">
              <i class="pi pi-spin pi-spinner text-xl text-zinc-400" />
            </div>
            <div v-else>{{ getSystemByID(data.id)?.product_type_name }}</div>
          </template>
        </Column>
        <Column
          class="no-top-border-row"
          field="product_type_name"
          :header="$t('hardwareSystem.assetName')"
          :sortable="true"
        >
          <template #body="{ data }">
            <div>{{ data.name }}</div>
          </template>
        </Column>
        <Column
          class="no-top-border-row"
          field="custom_id"
          :header="$t('hardwareSystem.nidecId')"
          :sortable="true"
        >
          <template #body="{ data }">
            <div>{{ data.nidec_id }}</div>
          </template>
        </Column>
        <Column
          class="no-top-border-row"
          field="custom_id"
          :header="$t('hardwareSystem.connectorStatus')"
          :sortable="true"
        >
          <template #body="{ data }">
            <div v-if="isLoading">
              <i class="pi pi-spin pi-spinner text-xl text-zinc-400" />
            </div>
            <div v-else>
              <HardwareSystemConnectorStates
                :system="getSystemByID(data.id)"
                v-if="data && getSystemByID(data.id)"
                :data-cy="`connectorStatus-system-${data.id}`"
                :connectorState="getSystemByID(data.id).connector_state"
              >
              </HardwareSystemConnectorStates>
            </div>
          </template>
        </Column>
      </DataTable>
      
      <div v-if="!system.combined_system_setup.setup_valid" class="bg-red-100 mt-8">
        <exclamation-triangle-icon class="h-4 w-4 text-red-500" />
        <p class="text-sm text-red-500">
          {{ system.combined_system_setup.setup_error }}
        </p>
      </div> 
    </PanelWithEdit>
  </div> 

</template> 
<script setup lang="ts">
import { useRouter } from 'vue-router';
import { watch, ref, onMounted, computed, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { useChargeParksStore } from '@/stores/admin/chargeParks/chargeParks.store';
import { CHARGE_PARKS, HARDWARE_SYSTEMS } from '@/utils/routeNames';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline';
import PanelWithEdit from '@/components/common/panel/PanelWithEdit.vue';
import Button from 'primevue/button';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
import HardwareSystemConnectorStates from '../../HardwareSystemConnectorStates.vue';
const router = useRouter();
const chargeParksStore = useChargeParksStore();
const { chargeParkEditionData } = storeToRefs(chargeParksStore);
const props = defineProps<{
  system: HardwareSystem;
}>();
const onRowSelect = (event: any) => {
  const route = `/${HARDWARE_SYSTEMS}/${event.data.id}`;
  if (event.originalEvent.ctrlKey) {
    const routeData = router.resolve(route);
    window.open(routeData.href, '_blank');
  } else {
    router.push(route);
  }
};
const systemStore = useHardwareSystemsStore();

const emit = defineEmits(['refetchSystem', 'configurePressed']);

const isLoading = ref(true);
const isParentSystem = computed(()=>!!props.system.combined_system_setup.parent)
const getSystemByID = (id: number): any => {
  return systemStore.systems.find((item) => item.id === id);
};
const systemsInTable = computed(()=>isParentSystem.value ?[props.system.combined_system_setup.parent] : props.system.combined_system_setup.children)

const parentSystemDetails = computed(() => {
  if (!props.system.combined_system_setup.parent) return {}
  return {
    product_type_name: getSystemByID(props.system.combined_system_setup.parent.id)?.product_type_name,
    nidec_id: props.system.combined_system_setup.parent?.nidec_id,
    name: props.system.combined_system_setup.parent?.name,
    custom_id: props.system.combined_system_setup.parent?.custom_id
  
  };
});
const  showSystem = (id: number | undefined) => {
  if (!id) return;
  router.push(`/${HARDWARE_SYSTEMS}/${id}`);
};
onMounted(async () => {
  await systemStore.fetchSystemsList();
  isLoading.value = false;
});

onUnmounted(async () => {});

watch(props, async () => {}, { immediate: true, deep: true });
</script>

<style scoped lang="scss"></style>
