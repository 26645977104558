import { defineStore } from 'pinia';
import * as api from './controlPanel.api';
import type { Dashboard, Panel } from '@/models/controlPanel/dashboard';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { ProductType } from '@/models/productTypes.model';
import { DataClient, type LastDataEvents } from '@/logic/controlPanel/dataClient';
import { pathMatcherWithWildcards } from '@/logic/controlPanel/productType';
const pollIntervalMs = import.meta.env.DEV ? 2000 : 10000;

export const useControlPanelCacheStore = defineStore('controlPanelCache', {
  state: () => ({
    allSystems: {} as Record<number, HardwareSystem>,
    productTypes: {} as Record<string, ProductType>,
    dashboards: {} as Record<number, Dashboard>,
  }),
  actions: {
    async getHardwareSystemFromCache(systemId: HardwareSystem['id']): Promise<HardwareSystem> {
      if (this.allSystems[systemId]) {
        api.fetchHardwareSystem(systemId).then((data) => (this.allSystems[systemId] = data));
        return this.allSystems[systemId];
      }
      return await api
        .fetchHardwareSystem(systemId)
        .then((data) => (this.allSystems[systemId] = data));
    },
    async getProductTypeFromCache(name: ProductType['name']): Promise<ProductType> {
      if (this.productTypes[name]) {
        api.fetchProductType(name).then((data) => (this.productTypes[name] = data));
        return this.productTypes[name];
      }
      return await api.fetchProductType(name).then((data) => (this.productTypes[name] = data));
    },
    async getDashboardFromCache(
      id: Dashboard['id'],
      systemId: HardwareSystem['id']
    ): Promise<Dashboard> {
      if (this.dashboards[id]) {
        api.fetchDashboard(systemId).then((data) => {
          this.dashboards[id] = data;
        });
        return this.dashboards[id];
      }
      return await api.fetchDashboard(systemId).then((data) => {
        this.dashboards[id] = data;
        return data;
      });
    },
  },
});

export const useControlPanelStore = defineStore('controlPanel', {
  state: () => ({
    systemId: null as HardwareSystem['id'] | null,
    hardwareSystem: null as HardwareSystem | null,
    allSystems: {} as Record<number, HardwareSystem>,
    productTypes: {} as Record<number, ProductType>,
    productType: null as ProductType | null,

    dashboards: {} as Record<number, Dashboard>,
    dashboard: null as Dashboard | null,
    activePanelId: null as Panel['id'] | null,

    dataClient: new DataClient(pollIntervalMs),

    initializationError: null as string | null,
  }),
  getters: {
    getSettingByPath: (state) => {
      return (path: string) =>
        state.productType?.properties.settings.find((s) => pathMatcherWithWildcards(path, s.path));
    },
    getTelemetryByChannel: (state) => {
      return (channel: string) => state.productType?.properties.channels[channel];
    },
  },
  actions: {
    async initialize(systemId: HardwareSystem['id']): Promise<void> {
      if (this.systemId === systemId) return;
      console.log('Control panel init', systemId);

      try {
        const cacheStore = useControlPanelCacheStore();
        const system = await cacheStore.getHardwareSystemFromCache(systemId);
        console.log('control panel using', system);
        Promise.all([
          cacheStore.getDashboardFromCache(system.default_dashboard_id, systemId),
          cacheStore.getProductTypeFromCache(system.product_type_name),
        ]).then((tasks) => {
          console.log('Recieved new dashboard data', tasks);
          this.dashboard = tasks[0];
          this.productType = tasks[1];
        });

        this.hardwareSystem = system;
        this.systemId = systemId;
        this.initializationError = null;
      } catch (e) {
        if (e instanceof Error) this.initializationError = e.message;
        else this.initializationError = String(e);
        console.error(e);
        return Promise.reject(e);
      }
    },

    async startPolling(dashboardId?: number, panelId?: string): Promise<void> {
      if (!this.systemId) return;
      await this.dataClient.startPolling(this.systemId, dashboardId, panelId);
    },

    async stopPolling(): Promise<void> {
      this.dataClient.stopPolling();
    },

    async refreshData(options?: { useCacheIfAvailable?: boolean }): Promise<void> {
      await this.dataClient.sendSingleRequest(options);
    },

    getSettingValueFromCache(path: string): LastDataEvents['settings'][number] | undefined {
      return this.dataClient.getFromCache('setting', path);
    },

    getTelemetryValueFromCache(path: string): LastDataEvents['telemetry'][number] | undefined {
      return this.dataClient.getFromCache('telemetry', path);
    },

    async applyChange(path: string, value: any): Promise<void> {
      if (!this.hardwareSystem) return;
      await api.applyPatch(this.hardwareSystem.id, [{ path, value }]);
    },

    async executeCommand(path: string, force: boolean, args?: object): Promise<void> {
      if (!this.hardwareSystem) return;
      return api.executeCommand(this.hardwareSystem.id, path, force, args);
    },

    async destroy(): Promise<void> {
      this.dataClient.stopPolling();
      this.$reset();
    },
  },
});
