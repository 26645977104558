<template>
  <error-bar v-if="error !== null" :error="error" />
  <control-panel-panels
    v-else-if="props.system !== null && panels.length > 0"
    :system="props.system"
    :panels="panels"
  />
  <div v-else-if="loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <div v-else>
    <p>No settings available</p>
  </div>
</template>

<script setup lang="ts">
import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { ProductType } from '@/models/productTypes.model';
import type { Panel } from '@/models/controlPanel/dashboard';
import ControlPanelPanels from '@/components/hardwareSystems/controlPanel/ControlPanelPanels.vue';
import { onMounted, ref } from 'vue';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
import { fetchSystemLastData } from '@/logic/controlPanel/dataClient';
import { buildSettingsPanels } from '@/components/hardwareSystems/controlPanel/controlPanel.settings.logic.productProperties';

const props = defineProps<{
  system: HardwareSystem | null;
  productType: ProductType | null;
}>();

const panels = ref<Panel[]>([]);
const loading = ref<boolean>(true);
const error = ref<string | null>(null);

onMounted(() => {
  if (!props.system) return;
  if (!props.productType) return;

  fetchSystemLastData(props.system.id)
    .then((data) => {
      console.log(data.settings.map((setting) => setting.path));
      panels.value = buildSettingsPanels(
        data.settings.map((setting) => setting.path),
        props.productType!.properties.settings
      );
    })
    .catch((err) => {
      error.value = err.message;
    })
    .finally(() => {
      loading.value = false;
    });
});
</script>

<style scoped lang="scss"></style>
