<template>
  <ChargeParkPicker
    assets-column
    class="pt-4"
    owner-label="organisation"
    select-mode="multiple"
    :value="selectedSiteIds"
    @update:value="setSelectedSiteIds"
    :compatible-systems="props.productTypeName ? [props.productTypeName] : undefined"
    :sites="props.sites"
    show-location
  >
    <template #header>
      <p class="block title font-medium field-edit-label">Sites</p>
    </template>
    <template #subheader>
      <p class="block text-grey text-sm">Which sites should get this branding configuration</p>
    </template>
  </ChargeParkPicker>

  <div v-if="sitesWithConflict.length > 0" class="px-12 pb-4">
    <warning-bar
      :message="
        t('warnings.sites.resourceConflict', {
          sites: sitesWithConflict.map((site) => site.name).join(', '),
          resource: 'branding',
          count: sitesWithConflict.length,
        })
      "
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { ChargePark } from '@/models/chargeParks.model';
import type { SiteValidationErrors } from '@/stores/admin/branding/ui.validation';
import ChargeParkPicker from '@/components/chargeParks/ChargeParkPicker.vue';
import WarningBar from '@/components/common/bar/WarningBar.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps<{
  currentBrandingId?: number;
  selectedSiteIds: ChargePark['id'][];
  sites: ChargePark[];
  productTypeName?: string;
  validationErrors?: SiteValidationErrors;
}>();

const emit = defineEmits<{
  (event: 'update:selected-site-ids', selectedSiteIds: ChargePark['id'][]): void;
}>();

const selectedSiteIds = ref(props.selectedSiteIds);

const sitesWithConflict = computed(() => {
  let conflictingSites = props.sites.filter(
    (site) => selectedSiteIds.value.includes(site.id) && !!site.branding
  );

  if (props.currentBrandingId !== undefined) {
    conflictingSites = conflictingSites.filter(
      (site) => site.branding?.id !== props.currentBrandingId
    );
  }

  return conflictingSites;
});

function setSelectedSiteIds(newSelectedSiteIds: ChargePark['id'][]) {
  selectedSiteIds.value = newSelectedSiteIds;
  emit('update:selected-site-ids', selectedSiteIds.value);
}
</script>

<style scoped lang="scss"></style>
