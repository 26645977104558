<template>
  <ChargingSessionDetail
    v-if="selectedSessionID"
    :sessionID="selectedSessionID"
    @closeDetailsPage="closeDetailsPage"
  ></ChargingSessionDetail>
  <ChargingSessionsTable
    v-else
    @showDetails="showDetails"
    :system_id="props.system_id"
    :charge_park_id="props.charge_park_id"
  ></ChargingSessionsTable>
</template>

<script setup lang="ts">
import ChargingSessionsTable from '@/components/chargingSessions/ChargingSessionsTable.vue';
import { onMounted, ref, watch } from 'vue';
import type { ChargingSession } from '@/models/chargingSessions.model';
import { useRoute, useRouter } from 'vue-router';
import ChargingSessionDetail from '@/components/chargingSessions/ChargingSessionDetail.vue';
import { CHARGING_SESSIONS } from '@/utils/routeNames';

const props = defineProps<{
  charge_park_id?: number;
  system_id?: number;
}>();

const route = useRoute();

const router = useRouter();

const selectedSessionID = ref<ChargingSession['id'] | null>();

const showDetails = (id: number) => {
  selectedSessionID.value = id;
};

const closeDetailsPage = () => {
  selectedSessionID.value = null;
  if (route.name === 'ChargingSessionsDetails') router.replace(`/${CHARGING_SESSIONS}`);
};

onMounted(async () => {
  if (route.name === 'ChargingSessionsDetails' && route.params.id)
    selectedSessionID.value = Number(route.params.id);
});

watch(route, (to) => {
  if (route.name === 'ChargingSessionsDetails' && route.params.id) {
    selectedSessionID.value = Number(route.params.id);
  } else {
    selectedSessionID.value = null;
  }
});
</script>

<style lang="scss" scoped>
.container {
  max-width: max-content;
}
</style>
