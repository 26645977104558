import type { ProductTypeConfig } from '@/models/alerts.model';
import type { IdName } from '@/models/common.model';

export interface Report {
  id: number;
  name: string;
  active: boolean;
  contacts: ReportContact[];
  frequency: number;
  charge_parks: IdName[];
  template_id: string;
  created_by_user_name: string;
  next_report_at: string | null;
  start_date: Date | string | null;
  end_date: Date | string | null;
  report_type: string;
}

export interface GeneratedReport {
  id: number;
  report_name: string;
  report_template_id: string;
  report_template_name: string;
  charge_parks: IdName[];
  contacts: ReportContact[];
  file_url: string;
  created_at: string;
}

export interface ReportTemplate {
  id: string;
  name: string;
  description: string;
  productTypeConfig: ProductTypeConfig[];
  supportedProductTypes: string[];
}

export interface ReportEditData {
  name: string;
  frequency: number | null;
  charge_parks: number[];
  contacts: number[];
  template_id: string;
  next_report_at: string | null;
  start_date: string | null;
  end_date: string | null;
  report_type: string | null | undefined;
}

export interface ReportBatchEditData {
  items: {
    id: number;
    active: boolean;
  }[];
}

export interface ReportFailedId {
  id: number;
  error: string;
}

export interface ReportContact {
  id: number;
  name: string;
  type: string;
}

export enum ReportStep {
  Template = 'template',
  Configuration = 'configuration',
  Sites = 'sites',
  Contacts = 'contacts',
  Preview = 'preview',
}

export enum ReportType {
  Instant = 'instant',
  Periodic = 'periodic',
}
