<template>
  <div class="px-3 py-3 flex items-center">
    <MediaThumbnail :media="item" />

    <div class="ml-4 mr-2 flex-1">
      <p class="font-semibold w-[130px] truncate ...">{{ item.file_name }}</p>
      <div class="flex items-baseline text-sm text-gray-400">
        <p>{{ item.file_type }}</p>
        <span class="mx-1"> &#8226; </span>
        <p>{{ fileSizeToHumanReadable(item.file_size) }}</p>
      </div>
      <Chip
        class="text-xs"
        :style="mediaStore.getMediaStatusStyle(Number(item.status))"
        :label="mediaStore.getMediaStatusByID(Number(item.status))?.name"
      ></Chip>
    </div>

    <Button
      :label="$t('add')"
      icon="pi pi-plus"
      class="p-button-outlined p-button-white p-button-sm"
      @click="onClick"
    />
  </div>
</template>

<script setup lang="ts">
import Chip from 'primevue/chip';

import type { Media } from '@/models/media.model';
import { fileSizeToHumanReadable } from '@/utils/textFormatting';
import Button from 'primevue/button';
import MediaThumbnail from '@/components/common/thumbnail/MediaThumbnail.vue';
import { useMediaStore } from '@/stores/admin/media/media.store';

const mediaStore = useMediaStore();

defineProps<{
  item: Media;
}>();

const emit = defineEmits(['add']);

function onClick() {
  emit('add');
}
</script>
