<template>
  <loading-spinner v-if="isLoading" />

  <div v-else-if="branding !== undefined">
    <page-header
      class="header"
      :title="branding.name"
      :subtitle="`Branding Configuration - ${productType?.name}`"
      show-back-button
    >
      <template v-slot:buttons>
        <custom-button
          @click="isEditing = true"
          :disabled="!branding.writable"
          :disabled-tooltip="
            t('permissions.info.parentOrgOwnsResource.editNotAllowed', {
              resource: t('resources.branding', { count: 1 }),
            })
          "
          data-cy="edit-branding-button"
        >
          <pencil-icon class="icon" />
          <span>Edit</span>
        </custom-button>
        <custom-button
          @click="isDeleting = true"
          :disabled="!branding.writable"
          :disabled-tooltip="
            t('permissions.info.parentOrgOwnsResource.deleteNotAllowed', {
              resource: t('resources.branding', { count: 1 }),
            })
          "
          data-cy="delete-branding-button"
        >
          <trash-icon class="icon" />
          <span>Delete</span>
        </custom-button>
      </template>
    </page-header>

    <error-bar v-if="error !== undefined" title="Load Error" :message="error" />

    <div class="details-container" :key="lastFetched">
      <div class="card">
        <div class="card-header">
          <h1>Branding Configuration</h1>
        </div>
        <div
          v-for="group of productType?.properties?.branding_template?.settings"
          :key="group.id"
          class="group-container"
        >
          <div class="card-header">
            <h3>{{ group.label }}</h3>
          </div>
          <div class="setting-group">
            <div
              v-for="setting of group.settings"
              :key="setting.id"
              class="flex items-center justify-between"
            >
              <label>
                {{ setting.label }}
              </label>
              <string-number-display
                v-if="
                  setting.type === 'string' ||
                  setting.type === 'url' ||
                  setting.type === 'email' ||
                  setting.type === 'phone' ||
                  setting.type === 'number'
                "
                :value="branding?.settings[group.id]?.[setting.id]"
              />
              <color-display
                v-if="setting.type === 'color'"
                :value="branding?.settings[group.id]?.[setting.id]"
              />
              <enum-display
                v-if="setting.type === 'single-enum' || setting.type === 'multi-enum'"
                :value="branding?.settings[group.id]?.[setting.id]"
                :options="setting.options"
              />
              <boolean-display
                v-if="setting.type === 'boolean'"
                :value="branding?.settings[group.id]?.[setting.id]"
              />
              <image-display
                v-if="setting.type === 'image'"
                :id="branding?.settings[group.id]?.[setting.id]"
                :product-type-name="branding.product_type_name"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h1>Sites</h1>
        </div>

        <div class="site-list-container">
          <div class="site-row" v-for="site of branding?.charge_parks" :key="site.id">
            <p>{{ site.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <branding-create-edit-modal
    v-if="branding"
    :open="isEditing"
    :config="branding"
    mode="EDIT"
    @cancelled="onEditCancel"
    @updated="onUpdated"
  />
  <branding-delete-modal
    v-if="branding"
    :branding="branding"
    :open="isDeleting"
    @canceled="isDeleting = false"
    @deleted="navigateToOverview"
  />
</template>

<script setup lang="ts">
import type { FullBrandingConfiguration } from '@/stores/admin/branding/branding.types';
import type { ProductType } from '@/models/productTypes.model';
import { fetchProductType } from '@/stores/admin/controlPanel/controlPanel.api';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { fetchBrandingDetailsById } from '@/stores/admin/branding/ui.api';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';
import StringNumberDisplay from '@/components/branding/display/StringDisplay.vue';
import ColorDisplay from '@/components/branding/display/ColorDisplay.vue';
import EnumDisplay from '@/components/branding/display/EnumDisplay.vue';
import BooleanDisplay from '@/components/branding/display/BooleanDisplay.vue';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

import { PencilIcon, TrashIcon } from '@heroicons/vue/24/outline';
import BrandingCreateEditModal from '@/components/branding/modal/BrandingCreateEditModal.vue';
import ImageDisplay from '@/components/branding/display/ImageDisplay.vue';
import { fetchChargeParks } from '@/stores/admin/chargeParks/chargeParks.api';
import type { ChargePark } from '@/models/chargeParks.model';
import BrandingDeleteModal from '@/components/branding/modal/BrandingDeleteModal.vue';
import router from '@/router';
import { BRANDING } from '@/utils/routeNames';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import CustomButton from '@/components/common/button/CustomButton.vue';
import { useI18n } from 'vue-i18n';

const { params } = useRoute();
const id = Number(params.id);
const { t } = useI18n();
const isLoading = ref(true);
const productType = ref<ProductType | undefined>(undefined);
const branding = ref<FullBrandingConfiguration | undefined>(undefined);
const lastFetched = ref(Date.now());
const chargeParks = ref<ChargePark[]>([]);
const error = ref<string | undefined>(undefined);

const isEditing = ref(false);
const isDeleting = ref(false);

function onEditCancel() {
  isEditing.value = false;
}

function onUpdated() {
  isEditing.value = false;
  fetchBrandingDetails();
}

function navigateToOverview() {
  isDeleting.value = false;
  router.push(`/${BRANDING}`);
}

async function fetchBrandingDetails() {
  isLoading.value = true;
  try {
    branding.value = await fetchBrandingDetailsById(id);
    productType.value = await fetchProductType(branding.value?.product_type_name);
    chargeParks.value = (await fetchChargeParks()).data ?? [];
  } catch (e) {
    console.error(e);
    error.value = (e as Error).message || 'Unknown error';
  } finally {
    isLoading.value = false;
    lastFetched.value = Date.now();
  }
}

onMounted(async () => {
  await fetchBrandingDetails();
});
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

label {
  font-size: 0.9rem;
  color: var(--gray-500);
}

.icon {
  height: 1rem;
  width: 1rem;
}

.details-container {
  position: relative;
  margin: 1rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  z-index: 0;
}

.group-container {
  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-200);
  }
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
  border-radius: var(--rounded-md);
  overflow: clip;
  background-color: var(--gray-0);
  border: 1px solid var(--gray-200);
}

.setting-group {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-200);
  }
}

.subtitle {
  font-size: 1rem;
  color: var(--gray-400);
}

.site-list-container {
  padding: 0.75rem;

  .site-row {
    color: var(--gray-500);

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}

.card-header {
  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);
  padding: 0.5rem 1rem;

  h1 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--gray-800);
  }

  h3 {
    font-size: 0.825rem;
    font-weight: 500;
    color: var(--gray-400);
  }
}
</style>
