import type {
  Widget,
  PlotValue,
  WidgetData,
  PieChartWidget,
  BarChartWidget,
  WidgetQuery,
  WidgetFilter,
  RenderableWidget,
  MapWidget,
  TableWidget,
  Dimension,
} from '@/stores/admin/dashboard/dashboard.types';
import { allLettersAreCapital, prettify } from '@/utils/textFormatting';
import type { EChartsOption } from 'echarts';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';
import type { Result } from '@/@types';
import { Err, Ok } from '@/@types';
import type { XAXisOption } from 'echarts/types/dist/shared';
import { convertValue, getUnitForSystem } from '@/utils/units';
import {
  loadWidgetData,
  fetchCount,
  fetchAverageDuration,
} from '@/stores/admin/dashboard/dashboard.api';
import type { UnitSystem } from '@/models/datatypes.model';
import type { ColorGenerator } from '@/stores/admin/dashboard/dashboard.logic.colors';

/**
 * Type definition for a summary item in a dashboard.  Contains an ID, name, value, and color.
 */
export type SummaryItem = {
  id: string;
  name: string;
  value: PlotValue;
  color: string;
};

/**
 * Creates a deep copy of an object using JSON.parse/JSON.stringify. This is a shallow copy;
 * nested objects will not be deeply cloned.  Use with caution for complex objects.
 * @param obj - The object to be copied.  Can be any type.
 * @returns A copy of the input object.  Will be the same type as the input.
 */
const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

/**
 * Merges additional filters into an existing WidgetQuery.  Replaces existing filters with the same dimension.
 * This ensures that additional filters override any previously defined filters for the same dimension.
 * @param query - The original WidgetQuery object containing existing filters.
 * @param additionalFilters - An array of WidgetFilter objects to be merged.
 * @returns A new WidgetQuery object with the merged filters.  The original query is not modified.
 */
export function mergeFilters(query: WidgetQuery, additionalFilters: WidgetFilter[]): WidgetQuery {
  const queryWithAllFilters = deepCopy(query); // Create a copy to avoid modifying the original

  // Filter out existing filters that are being replaced by new ones, then concatenate the new filters.
  queryWithAllFilters.transform.filters = query.transform.filters
    .filter((f) => !additionalFilters.some((af) => af.dimension === f.dimension))
    .concat(additionalFilters);

  return queryWithAllFilters;
}

/**
 * Truncates a Date object to the beginning of the specified time bin.  Useful for grouping data.
 *  Handles various bin sizes (seconds, minutes, hours, days, weeks, months, years).
 * @param date - The Date object to truncate.
 * @param binSize - The size of the time bin to truncate to (e.g., 's' for seconds, 'd' for days).
 * @returns A new Date object truncated to the beginning of the specified bin.
 */
function truncateTimestampToBinSize(date: Date, binSize: string): Date {
  if (binSize.endsWith('s')) date.setMilliseconds(0);
  if (binSize.endsWith('min')) date.setSeconds(0, 0);
  if (binSize.endsWith('h')) date.setMinutes(0, 0, 0);
  if (binSize.endsWith('d')) date.setHours(0, 0, 0, 0);
  if (binSize.endsWith('w')) date.setHours(0, 0, 0, 0);
  if (binSize.endsWith('m')) date.setDate(1);
  if (binSize.endsWith('y')) date.setMonth(0, 1);
  return date;
}

/**
 * Creates a function to convert a value to a PlotValue based on the type and bin size.  This is a higher-order function; it returns a function.
 * @param type - The type of the PlotValue ('time' or 'category').  Determines how the value is handled.
 * @param binSize - The optional bin size (e.g., 's', 'min', 'h', 'd').  If provided, the timestamp is truncated to the beginning of the bin.
 * @returns A function that takes a PlotValue and returns a PlotValue of the specified type, potentially truncated to a bin.
 */
function toPlotValue(type: 'time' | 'category', binSize?: string): (val: PlotValue) => PlotValue {
  return function (val: PlotValue): PlotValue {
    if (type === 'category') return val;
    if (binSize != undefined)
      return truncateTimestampToBinSize(new Date(+val * 1000), binSize).getTime();
    return new Date(+val * 1000).getTime();
  };
}

/**
 * Extracts the group key from a WidgetQuery. This key is used for grouping data in charts. If no groupBy is defined, it returns a default key.
 * @param query - The WidgetQuery object.
 * @param defaultKey - The default key to use if no groupBy is specified in the query.
 * @returns The group key, or the default key if no groupBy is defined.  Returns undefined if neither is present.
 */
export function getGroupKey(query: WidgetQuery, defaultKey?: string): string | undefined {
  return query.transform.groupBy?.[0]?.target || defaultKey;
}

/**
 * Extracts the bin size from a WidgetQuery object.  The bin size determines the granularity of time-based aggregations.
 * @param query - The WidgetQuery object from which to extract the bin size.
 * @returns The bin size string (e.g., 's', 'min', 'h', 'd'), or undefined if not specified.
 */
export function getBinSize(query: WidgetQuery): string | undefined {
  return query.transform.groupBy?.[0]?.binSize;
}

/**
 * Builds an eCharts x-axis option for time data. This function configures the x-axis for charts displaying time series data.
 * @param show - A boolean indicating whether the x-axis should be displayed (defaults to true).
 * @returns An object containing the configuration for the eCharts x-axis, specifying it as a time axis.
 */
export function buildTimeXAxis(show = true): XAXisOption {
  return {
    type: 'time',
    show,
  };
}

/**
 * Builds an eCharts x-axis option for category data.  This function sets up the x-axis for categorical data in charts.
 * @param show - A boolean indicating whether to show the x-axis (defaults to true).
 * @param resolver - An optional function to transform the category values before display on the axis.
 * @returns An object containing the configuration for the eCharts x-axis, specifying it as a category axis. Includes options for label rotation.
 */
function buildCategoryXAxis(show = true, resolver?: (val: any) => string): XAXisOption {
  //The formatter function formats the category labels.  If a resolver function is provided, it uses that; otherwise it uses a default string conversion.
  const formatter = resolver ? (val: string) => resolver(val) : (val: any) => String(val);
  return {
    type: 'category',
    axisLabel: { formatter, rotate: 45 }, // Rotate labels by 45 degrees for better readability
    show,
  };
}

/**
 * Resolves a dimension value using a context resolver. This function handles both single and array values for dimensions.
 * @param dimensionId - The ID of the dimension to resolve.
 * @param dimensionVal - The dimension value (can be a single value or an array).
 * @param contextResolver - The context resolver object used to obtain dimension resolvers.
 * @returns A Promise that resolves to the resolved dimension value.  Preserves array structure if input is an array.
 */
export async function resolve<T extends string | number | string[] | number[]>(
  dimensionId: WidgetFilter['dimension'],
  dimensionVal: T,
  contextResolver: WidgetContextResolver
): Promise<T> {
  //Handle array values by recursively calling resolve for each element and using Promise.all to ensure parallel processing.
  if (Array.isArray(dimensionVal)) {
    return (await Promise.all(
      dimensionVal.map((val) => resolve(dimensionId, val, contextResolver))
    )) as T;
  }

  //Initialize the context resolver and retrieve the resolver function for the specific dimension.
  await contextResolver.init();
  const resolver = contextResolver.getResolverFor(dimensionId);
  //If no resolver is found, return the original value.
  if (resolver === undefined) return dimensionVal;
  //Otherwise, apply the resolver and return the result, correctly typed.
  return resolver(dimensionVal.toString()) as T;
}

/**
 * Type guard to check if a value is a number array.
 * @param val The value to check.
 * @returns True if the value is a number array, false otherwise.
 */
function isNumberArray(val: any): val is number[] {
  return Array.isArray(val) && typeof val[0] === 'number';
}

/**
 * Builds an echarts option for a pie or bar chart widget. This function constructs the configuration object for rendering a pie or bar chart using the echarts library.
 * @param widget - The widget configuration object, containing details about the chart type and data.
 * @param colors - A color generator function to provide colors for the chart elements.
 * @param data - The data for the chart, in the format expected by echarts.
 * @param contextResolver - A context resolver to handle dimension values and unit conversions.
 * @returns A Promise that resolves to a Result object containing the echarts configuration object or an error.  Uses Result for better error handling.
 */
export async function buildEchartsOption(
  widget: PieChartWidget | BarChartWidget,
  colors: ColorGenerator,
  data: WidgetData,
  contextResolver: WidgetContextResolver
): Promise<Result<EChartsOption>> {
  const type = widget.type; // Get the chart type (pie or bar) from the widget configuration

  //Extract group key and bin size from query, or use defaults
  let groupKey;
  let binSize;
  if (widget.query) {
    groupKey = getGroupKey(widget.query);
    binSize = getBinSize(widget.query);
  } else {
    groupKey = 'value'; //Default group key if not specified
  }

  //Determine the x-axis type based on the group key.
  const xAxisType = groupKey === 'time' ? 'time' : 'category';

  //Map data to echarts format. Uses toPlotValue to handle time and category data, potentially with binning.
  const xAxisData: PlotValue[] = data.series[0].map(toPlotValue(xAxisType, binSize));
  let yAxisData = data.series[1];

  //Determine color mapping based on widget configuration.
  let colorList = undefined;
  if (widget.presentation?.colorMap?.colorBy === 'index') {
    colorList = xAxisData.map((val) => colors.get(val));
  }
  if (widget.presentation?.colorMap?.colorBy === 'value') {
    colorList = yAxisData.map((val) => colors.get(val));
  }

  //Get the formatter for x-axis labels.
  const xAxisFormatter = contextResolver.getResolverFor(groupKey);

  //Build the x-axis configuration.
  const xAxis =
    xAxisType === 'time'
      ? buildTimeXAxis(type != 'pie')
      : buildCategoryXAxis(type != 'pie', xAxisFormatter);

  //Handle unit conversion.
  let currentYAxisUnit = '';
  // load units from product type channel
  if (widget.query) {
    const productType = widget.query.series.productType;
    const channel = widget.query.series.path.split(':').at(-1);
    currentYAxisUnit =
      productType && channel ? contextResolver.resolveUnit(productType, +channel) : '';
  }

  const desiredYAxisUnit = widget.axis.y.desiredUnit;

  if (currentYAxisUnit && desiredYAxisUnit && isNumberArray(yAxisData)) {
    const [convertedYAxisData, convertedYAxisUnit] = convertValue(
      yAxisData,
      currentYAxisUnit,
      widget.axis.y.desiredUnit
    );

    yAxisData = convertedYAxisData;
    currentYAxisUnit = convertedYAxisUnit;
  }

  //Construct the echarts option object.
  const echartsOption: Required<Pick<EChartsOption, 'xAxis'>> & EChartsOption = {
    tooltip: {
      trigger: 'item',
      confine: true,
    },
    dataset: {
      seriesLayoutBy: 'row',
      dimensions: [groupKey, 'value'],
      source: [xAxisData, yAxisData],
    },
    grid: { containLabel: true, left: '3%', right: '4%', bottom: '3%', top: '10%' },
    xAxis,
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (val: unknown) => formatValue(val, { unit: currentYAxisUnit, maxDecimals: 3 }),
      },
    },
    series: [
      {
        type,
        colorBy: 'data',
        color: colorList,
        tooltip: {
          show: true,
          formatter: (val) =>
            [
              val.marker,
              (xAxisFormatter?.(val.name) ?? val.name) + ':',
              formatValue(yAxisData[val.dataIndex], {
                unit: currentYAxisUnit,
                maxDecimals: 3,
              }),
            ].join(' '),
        },
      },
    ],
  };

  return Ok(echartsOption);
}

/**
 * Builds a summary of widget data. This function takes widget data and generates a concise summary, suitable for display in a dashboard.
 * @param widget - The widget configuration object.  Provides context for interpreting the data.
 * @param data - The widget data, typically containing series of x and y values.
 * @param colors - A color generator function to assign colors to the summary items.
 * @returns An array of SummaryItem objects, each representing a summary item with its ID, name, value, and color.
 */
export function buildSummary(
  widget: Widget,
  data: WidgetData,
  colors: ColorGenerator
): SummaryItem[] {
  //Map through the x-axis data (data.series[0]) and create a SummaryItem for each data point.
  return data.series[0].map((_, i) => ({
    //The ID of the summary item is the string representation of the x-axis value.
    id: String(data.series[0][i]),
    //The name is obtained by checking if all letters are capital, if so, uses prettify for better formatting. Otherwise it's just a string conversion.
    name: allLettersAreCapital(String(data.series[0][i]))
      ? prettify(String(data.series[0][i]))
      : String(data.series[0][i]),
    //The value of the summary item is the corresponding y-axis value from data.series[1].
    value: data.series[1][i],
    //The color is generated by the color generator function using the x-axis value.
    color: colors.get(data.series[0][i]),
  }));
}

/**
 * Type definition for a point on a map.  Used to represent locations with associated data on a map widget.
 */
export type MapPoint = {
  lat?: number;
  lng?: number;
  value: number | string;
  color: string;
  assetId?: string;
  assetName?: string;
};

/**
 * Builds an array of map points from widget data.  This function transforms raw data into a format suitable for display on a map.
 * @param widget - The MapWidget configuration object.
 * @param colors - A color generator function to assign colors to the map points.
 * @param data - The data for the map, typically containing series of IDs and values.
 * @param contextResolver - A context resolver to obtain asset information (latitude, longitude, name) based on IDs.
 * @returns A Promise that resolves to a Result object containing an array of MapPoint objects or an error. Uses Result for error handling.
 */
export async function buildMapPoints(
  widget: MapWidget,
  colors: ColorGenerator,
  data: WidgetData,
  contextResolver: WidgetContextResolver
): Promise<Result<MapPoint[]>> {
  //Error handling for undefined widget query.
  if (!widget.query) throw Error('widget.query is not defined!' + JSON.stringify(widget));
  //Get the group key from the widget query, defaulting to 'system.id'
  const groupKey = getGroupKey(widget.query, 'system.id');

  //Error handling for invalid group key
  if (groupKey === undefined || (groupKey !== 'chargepark.id' && groupKey !== 'system.id')) {
    return Err(new Error('Bad Query: No group by key'));
  }

  //Fetch assets from the context resolver using the group key and data series
  const assets = contextResolver.getAssets(groupKey, data.series[0]);
  const values = data.series[1];

  //Build an array of MapPoint objects
  const points: MapPoint[] = [];

  data.series[0].forEach((id, index) => {
    const asset = assets[id];
    //Only add a point if asset information is available
    if (asset === undefined) return;
    points.push({
      lat: asset?.lat,
      lng: asset?.lng,
      value: values[index],
      color: colors.get(values[index]),
      assetId: asset.id,
      assetName: asset.name,
    });
  });

  return Ok(points);
}

/**
 * Default map bounds if no points are provided. Coordinates for the center of Berlin.
 */
const centerOfBerlin = [52.520008, 13.404954];

/**
 * Computes the bounding box (bounds) of an array of map points.  Handles cases with 0, 1, and multiple points.
 * Uses the center of Berlin as a default if no points are provided.
 * @param points - An array of objects, each containing lat and lng properties representing geographical coordinates.
 * @returns A tuple representing the bounding box: [[minLat, minLng], [maxLat, maxLng]].
 */
export function computeBounds(points: { lat: number; lng: number }[]) {
  // Handle case with no points – use default coordinates (center of Berlin with padding).
  if (points.length == 0) {
    return [
      [centerOfBerlin[0] - 0.1, centerOfBerlin[1] - 0.1], //Min Latitude, Min Longitude
      [centerOfBerlin[0] + 0.1, centerOfBerlin[1] + 0.1], //Min Latitude, Min Longitude
    ] as [[number, number], [number, number]];
  }

  // Handle case with a single point – add padding to create a bounding box.
  if (points.length == 1) {
    const defaultPadding = 0.01; //Degrees of latitude/longitude for padding
    const point = points[0];
    return [
      [point.lat - defaultPadding, point.lng - defaultPadding],
      [point.lat + defaultPadding, point.lng + defaultPadding],
    ] as [[number, number], [number, number]];
  }

  // Handle case with multiple points – find the minimum and maximum latitude and longitude
  return points.reduce(
    (acc, cur) => {
      if (cur.lat < acc[0][0]) acc[0][0] = cur.lat;
      if (cur.lng < acc[0][1]) acc[0][1] = cur.lng;
      if (cur.lat > acc[1][0]) acc[1][0] = cur.lat;
      if (cur.lng > acc[1][1]) acc[1][1] = cur.lng;
      return acc;
    },
    [
      [Infinity, Infinity],
      [-Infinity, -Infinity],
    ] as [[number, number], [number, number]]
  );
}

////////// TABLE WIDGET

/**
 * Type definition for a column header in a table widget.
 */
export type TableColumnHeader = {
  key: string;
  label: string;
  unit?: string;
  dimension?: Dimension;
};

/**
 * Type definition for a raw row in a table widget.
 */
type RawTableRow = {
  [key: string]: string | number | boolean | null;
};

/**
 * Type definition for a row in a table widget.
 */
export type TableRow = {
  [key: string]: { value: string | number | boolean | null; id?: string };
};

/**
 * Type definition for table data.
 */
export type TableData = {
  headers: TableColumnHeader[];
  rows: TableRow[];
};

/**
 * Helper type for merging table data.
 */
type MergeInput = {
  key: string;
  x: (string | number)[];
  y: (string | number)[];
};

/**
 * Loads column data from the API.  Fetches data for a single column of the table.
 * @param index - The index of the column.
 * @param query - The WidgetQuery object defining the data query.
 * @param additionalFilters - Additional filters to apply to the query.
 * @returns An object containing the column index and the fetched data. Returns an empty data array if there's an error.
 */
async function loadColumnData(
  index: string,
  query: WidgetQuery,
  additionalFilters: WidgetFilter[]
) {
  let response: Result<WidgetData>;
  // Identify special cases for count and average duration columns.
  // The check for 'duration' in the path is crucial to distinguish between average aggregations for other metrics and the specifically handled average duration.
  // This is error prone, ideally these should have unique keys.
  if (query.transform.aggregation === 'count') {
    response = await fetchCount();
  } else if (
    query.transform.aggregation === 'avg' &&
    query.series.path.split(':')[1] === 'duration'
  ) {
    response = await fetchAverageDuration();
  } else {
    response = await loadWidgetData(query, additionalFilters);
  }
  // Handle API errors: If the response indicates an error, return an empty WidgetData object.
  // This prevents application crashes due to malformed data.
  if (!response.ok) return { index, data: { series: [] } as WidgetData };

  return { index, data: response.data };
}

/**
 * Type definition for a map of column keys to their original and desired units.
 */
type ColumnUnitMap = { [key: string]: { original?: string; desired?: string } };

/**
 * Formats a number to a specified number of decimal places.
 * @param val The number to format.
 * @param decimals The number of decimal places.
 * @returns The formatted number as a string.
 */
function formatToMaxDecimals(val: number, decimals: number): string {
  const factor = Math.pow(10, decimals);
  return (Math.round(val * factor) / factor).toString();
}

/**
 * Builds a map of column keys to their original and desired units.  Used for unit conversion.
 * @param widget - The TableWidget configuration.
 * @param contextResolver - The context resolver for resolving units.
 * @param desiredUnitSystem - The desired unit system for conversion.
 * @returns A ColumnUnitMap object mapping column keys to their original and desired units.
 */
function buildColumnUnitMap(
  widget: TableWidget,
  contextResolver: WidgetContextResolver,
  desiredUnitSystem?: UnitSystem
): ColumnUnitMap {
  const result: ColumnUnitMap = {};

  for (const [index, col] of widget.columns.entries()) {
    const key = String(index);
    const productType = col.query.series.productType;
    const channel = col.query.series.path.split(':').at(-1);
    const originalUnit =
      productType && channel ? contextResolver.resolveUnit(productType, +channel) : '';
    const originalUnitOverride = col.overrideSourceUnit;
    const desiredUnit = col.desiredUnit;

    //Handles optional unit overrides from the column configuration
    const original = originalUnitOverride || originalUnit;

    //Handles optional desired unit system for conversion
    if (desiredUnitSystem !== undefined && original !== undefined) {
      if (desiredUnit !== undefined) getUnitForSystem(desiredUnit, desiredUnitSystem);
      else getUnitForSystem(original, desiredUnitSystem);
    }

    result[key] = {
      original: originalUnitOverride || originalUnit,
      desired: desiredUnit,
    };
  }

  return result;
}

// Array of units that should not have a space between the value and the unit
const unitsWithoutSpace = ['%', '°C', '°F', '°'];

/**
 * Formats a numerical value with a specified unit and maximum number of decimal places. Handles non-number inputs by converting them to strings.
 * @param val - The value to format. Can be a number or a string.
 * @param opts - An object with options for formatting: maxDecimals (default 3) and unit.
 * @returns The formatted string representation of the value, including the unit if provided.
 */
function formatValue(val: unknown, opts: { maxDecimals?: number; unit?: string }): string {
  let strVal: string;
  if (typeof val === 'number') {
    strVal = formatToMaxDecimals(val, opts.maxDecimals ?? 3); // Default to 3 decimal places
  } else {
    strVal = String(val); // Handle non-number values
  }

  if (!opts.unit) return strVal; //Return value without unit if unit is not provided
  const separator = unitsWithoutSpace.includes(opts.unit) ? '' : ' '; //Add a space unless it's a special unit
  return strVal + separator + opts.unit;
}

/**
 * Loads table widget data, handles unit conversions, and formats the data for display.
 * @param widget The table widget configuration.
 * @param additionalFilters Additional filters to apply to the data query.
 * @param contextResolver The context resolver for resolving dimension values and units.
 * @param desiredUnitSystem The desired unit system for unit conversion.
 * @returns A TableData object containing the formatted table headers and rows.
 */
export async function loadTableWidgetData(
  widget: TableWidget,
  additionalFilters: WidgetFilter[],
  contextResolver: WidgetContextResolver,
  desiredUnitSystem?: UnitSystem
): Promise<TableData> {
  // Determine x-axis label based on dimension
  let xColumnLabel = widget.axis.x.label;
  if (widget.axis.x.dimension === 'system.id') xColumnLabel = 'System';
  if (widget.axis.x.dimension === 'chargepark.id') xColumnLabel = 'Site';

  // Create table headers, including x-axis label
  const columns: TableColumnHeader[] = [
    { key: 'x', label: xColumnLabel, dimension: widget.axis.x.dimension },
    ...widget.columns.map((col, idx) => ({
      key: String(idx),
      label: col.label,
      dimension: undefined,
    })),
  ];

  // Build map of column keys to their original and desired units
  const columnUnitMap = buildColumnUnitMap(widget, contextResolver, desiredUnitSystem);

  // Load data for each column concurrently
  const responses = await Promise.all(
    widget.columns.map((col, idx) => loadColumnData(String(idx), col.query, additionalFilters))
  );

  // Prepare data for merging into table rows
  const data: MergeInput[] = responses.map(({ index, data }) => ({
    key: index,
    x: data.series[0],
    y: data.series[1],
  }));

  // Perform unit conversions and format numerical values
  for (const col of data) {
    const unit = columnUnitMap[col.key];
    if (isNumberArray(col.y)) {
      const [convertedYAxisData, convertedYAxisUnit] = convertValue(
        col.y,
        unit.original,
        unit.desired
      );
      col.y = convertedYAxisData.map((val) =>
        formatValue(val, { unit: convertedYAxisUnit, maxDecimals: 3 })
      );
    }
  }

  // Merge data into table rows
  const rawTableRows = mergeToTableRows('x', data);

  // Convert raw table rows to TableRow type and add ID to the x-axis value if it represents an entity.
  const tableRows = rawTableRows.map((rawRow) => {
    const row: TableRow = {};
    for (const [key, val] of Object.entries(rawRow)) {
      row[key] = { value: val };
    }
    return row;
  });

  // Resolve entity names for system or charge park IDs, if applicable
  if (widget.axis.x.dimension === 'system.id' || widget.axis.x.dimension === 'chargepark.id') {
    if (widget.axis.x.dimension === 'system.id')
      for (const row of tableRows) {
        const id = String(row.x.value);
        const name = contextResolver.resolveEntityNameById(widget.axis.x.dimension, id) as string;
        row.x = { value: name, id };
      }
  }

  // Return formatted table data
  return { headers: columns, rows: tableRows };
}

/**
 * Merges multiple data series into a single array of table rows.
 * @param xKey The key for the x-axis values.
 * @param data An array of MergeInput objects, each representing a data series.
 * @returns An array of RawTableRow objects, representing the merged table rows.
 */
export function mergeToTableRows(xKey: string, data: MergeInput[]): RawTableRow[] {
  const result: RawTableRow[] = [];
  const keys = data.map((d) => d.key);
  const xDataMap = new Map<string | number, Record<string | number, string | number>>();

  // Create a map to store data points keyed by x-axis value
  for (const series of data) {
    for (let i = 0; i < series.x.length; i++) {
      const x = series.x[i];
      const y = series.y[i];
      const point = xDataMap.get(x) || {};
      xDataMap.set(x, { ...point, [series.key]: y });
    }
  }

  // Create table rows from the map
  for (const [x, y] of xDataMap.entries()) {
    const row: RawTableRow = {};
    row[xKey] = x;

    for (const key of keys) {
      row[key] = y[key] ?? null; // Handle missing values with null
    }

    result.push(row);
  }

  // Sort rows by x-axis value
  result.sort((a, b) => (a[xKey]! > b[xKey]! ? 1 : -1));
  return result;
}
