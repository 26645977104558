import type {
  FullBrandingConfiguration,
  WriteableBrandingConfiguration,
  BrandingMediaInfoResponse,
  BrandingMediaUploadResponse,
} from '@/stores/admin/branding/branding.types';
import axios from 'axios';

export async function createBranding(
  branding: WriteableBrandingConfiguration
): Promise<{ id: number }> {
  return axios.post(`/api/brandings/`, branding).then((r) => r.data);
}

export async function updateBranding(
  id: FullBrandingConfiguration['id'],
  branding: WriteableBrandingConfiguration
): Promise<{ id: number }> {
  return axios.patch(`/api/brandings/${id}/`, branding).then((r) => r.data);
}

export async function deleteBrandingConfiguration(id: FullBrandingConfiguration['id']) {
  return axios.delete(`/api/brandings/${id}/`);
}

export async function uploadBrandingMedia(file: File): Promise<BrandingMediaUploadResponse> {
  return axios
    .post(`/api/brandings/file/`, { file }, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((r) => r.data);
}

export async function fetchBrandingMediaInfo(
  mediaId: number | string
): Promise<BrandingMediaInfoResponse> {
  return axios.get(`/api/brandings/file/${mediaId}/`).then((r) => r.data);
}

export async function fetchDefaultBrandingMedia(productTypeName: string, mediaId: string) {
  return axios
    .get(`/api/brandings/static_file/?product_type_name=${productTypeName}&file_name=${mediaId}`)
    .then((r) => r.data);
}

export async function fetchBrandings(): Promise<FullBrandingConfiguration[]> {
  return axios.get(`/api/brandings/`).then((r) => r.data);
}

export async function fetchBrandingDetailsById(id: number): Promise<FullBrandingConfiguration> {
  return axios.get(`/api/brandings/${id}/`).then((r) => r.data);
}
